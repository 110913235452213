const SHAPE_CONFIG = {
  ask: {
    color: '#DE5D4E',
    text: 'ask'
  },
  bid: {
    color: '#4993F5',
    text: 'bid'
  }
};

class ChartUtils {
  static getSymbol(chartSymbol) {
    const parts = chartSymbol.split(':');
    const symbol = parts[parts.length - 1];
    window?.webkit?.messageHandlers?.symbolChanged?.postMessage(symbol);
    return symbol;
  }

  static createHorizontalLine(tvWidget, time, price, type) {
    return tvWidget.activeChart().createShape(
      { time, price },
      {
        shape: 'horizontal_line',
        disableSelection: true,
        disableSave: true,
        disableUndo: true,
        showInObjectsTree: false,
        text: SHAPE_CONFIG[type].text,
        zOrder: 'top',
        overrides: {
          showLabel: false,
          showTime: false,
          linestyle: 1,
          linecolor: SHAPE_CONFIG[type].color
        }
      }
    );
  }
}

export default ChartUtils;
