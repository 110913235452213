import React, { memo, useState } from 'react';
import BuySellButton from '../components/ActionButton';
import LotSizeController from '../components/LotSizeController';
import StopLossAndTakeProfit from '../components/StopLossAndTakeProfit';
import { useAppSelector } from '../../../redux/app';
import InputPrice from '../components/InputPrice';
import ExpirationDate from '../components/ExpirationDate';
import Expiration from '../components/Expiration';

const BuyLimitFunction = memo(({ isBuyLimit }) => {
  const payload = useAppSelector((state) => state.payload?.payload);
  const [error, setError] = useState(false);

  return (
    <div>
      <LotSizeController setError={setError} />
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Price</div>
        <div></div>
      </div>
      <InputPrice validate={isBuyLimit ? 2 : 3} className="mx-[10px] mt-2" />
      <div className="px-[12px]">
        <StopLossAndTakeProfit />
      </div>
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Expiration</div>
        <div></div>
      </div>
      <div style={{ margin: 10 }}>
        <Expiration />
      </div>
      {payload.type_time >= 2 ? <ExpirationDate inputClass="mt-2" /> : null}
      <BuySellButton typeBuy={isBuyLimit ? 2 : 3} />
    </div>
  );
});

BuyLimitFunction.displayName = 'BuyLimitFunction';

export default BuyLimitFunction;
