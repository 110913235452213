import React, { createContext, useEffect, useReducer, useState } from 'react';
import { signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { accountTradeApi, firebaseCustomApi, getMe } from '../../api/account';
import { AuthClient } from '../../grpc/generated/auth_grpc_web_pb';
import { TokenRequest } from '../../grpc/generated/auth_pb';
import { Notification } from '../../utilities/alert';
import { auth, logoutFirebase } from '../../firebase';
import { StorageService } from './storageService';
import { serverApiConfig } from '../../config';
import { handleTokenResponse } from './authService';
import ListArray from '../../data/listArray';

let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
  metatrader: [],
  activeAccount: null,
  isOtp: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, metatrader, isInitialized, activeAccount, isOtp } =
      action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user,
      metatrader,
      activeAccount,
      isOtp
    };
  },
  LOGIN: (state, action) => {
    const { user, metatrader, isInitialized, activeAccount, isOtp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isInitialized,
      user,
      metatrader,
      activeAccount,
      isOtp
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    metatrader: null,
    activeAccount: null,
    isOtp: false
  }),
  REGISTER: (state, action) => {
    const { user, metatrader } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      metatrader
    };
  },
  AUTHTOKEN: (state, action) => {
    const { user, metatrader, isInitialized, activeAccount, setToken, isOtp } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized,
      user,
      metatrader,
      activeAccount,
      setToken,
      isOtp
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  authWithToken: () => Promise.resolve(),
  getTokenFromUrl: () => Promise.resolve(),
  getFirebaseCustom: () => Promise.resolve(),
  reState: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const request = new google_protobuf_empty_pb.Empty();
  const lisArray = new ListArray();
  const tokenRequest = new TokenRequest();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [index, setIndex] = useState(0);

  const handleAuthError = async (error) => {
    if (error.response?.data?.code === 1010) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...initialState,
          isInitialized: false,
          isOtp: true
        }
      });
    } else {
      Notification({
        message: error.response?.data?.error || 'Authentication failed',
        title: 'Invalid',
        type: 'danger'
      });
      await logout();
    }
  };

  const handleTradeData = async (user, tradeData) => {
    if (!tradeData?.data.length) {
      Notification({
        message: 'Account trade not found',
        title: 'Invalid',
        type: 'danger'
      });
      dispatch({
        type: 'INITIALIZE',
        payload: {
          ...initialState,
          isInitialized: false
        }
      });
      return;
    }

    const token = await user.getIdToken();
    await initialize(token, tradeData);
  };

  const handleTokenError = async (working, index) => {
    if (working.length > index) {
      setTimeout(() => {
        setIndex((prev) => prev + 1);
      }, 1000);
      return null;
    }

    Notification({
      message: 'Token request failed',
      type: 'danger'
    });

    return {
      isAuthenticated: false,
      isInitialized: false,
      metatrader: null,
      activeAccount: null
    };
  };

  const getFirebaseCustom = async () => {
    let token = null;
    try {
      const { data } = await firebaseCustomApi();
      token = data.data.token;
      return token;
    } catch (error) {
      return;
    }
  };

  const getTokenFromAuth = async (working, index, token, data) => {
    const authClient = new AuthClient(serverApiConfig(working[index]?.api_server), null, null);

    tokenRequest.setMainToken(token);
    tokenRequest.setAccountTradeId(working[index].id);

    return new Promise((resolve) => {
      authClient.getToken(tokenRequest, {}, async (err, response) => {
        if (err) {
          const errorState = await handleTokenError(working, index);
          resolve(errorState);
        } else {
          const successState = await handleTokenResponse(response, working, index, data);
          resolve(successState);
        }
      });
    });
  };

  const initialize = async (token, data) => {
    const tk = StorageService.getItem('trade.token');
    const server = StorageService.getItem('trade.server');
    const acc = StorageService.getItem('iuxmarkets.active.account');

    try {
      // Initialize server and account if not present
      if (!server) {
        StorageService.setItem('trade.server', data.data[0]?.api_server);
      }
      if (!acc) {
        StorageService.setItem('iuxmarkets.active.account', data.data[0]?.id);
      }

      let state;
      if (tk) {
        const me = await getMe();
        await lisArray.getListArray(request, { Authorization: 'Bearer ' + tk });
        state = {
          isAuthenticated: true,
          isInitialized: false,
          metatrader: data.data,
          activeAccount: acc,
          user: me.data
        };
      } else {
        const working = data.data?.sort((a, b) => b.current_balance - a.current_balance) ?? [];

        if (working.length > 0) {
          state = await getTokenFromAuth(working, index, token, data);
          if (!state) return; // Handle retry case
        } else {
          state = {
            isAuthenticated: true,
            isInitialized: false,
            metatrader: [],
            activeAccount: null
          };
        }
      }

      dispatch({
        type: 'INITIALIZE',
        payload: state
      });
    } catch (error) {
      console.error('Initialize error:', error);
      await logout();
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          isInitialized: false,
          metatrader: [],
          activeAccount: null,
          isOtp: false
        }
      });
    }
  };

  const authWithToken = async ({ token, loading }) => {
    loading(true);
    await signInWithCustomToken(auth, token)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await accountTradeApi()
          .then(async ({ data }) => {
            await initialize(await user.getIdToken(), data);
          })
          .catch((e) => {
            if (e.response.data.code === 1010) {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: false,
                  isInitialized: false,
                  metatrader: [],
                  activeAccount: null,
                  isOtp: true
                }
              });
            }
          });
      })
      .catch((error) => {
        console.error('Error signing in on domain B:', error);
        loading(false);
      });
  };

  const login = async ({ username, password, setLoading }) => {
    await signInWithEmailAndPassword(auth, username, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const token = await user.getIdToken();
        window.electron?.store.set('token', token);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/user-not-found':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          case 'auth/wrong-password':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          case 'auth/invalid-email':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          default:
            return Notification({
              message: 'Something went wrong',
              type: 'danger'
            });
        }
        setLoading(false);
      });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('', {
      email,
      password,
      firstName,
      lastName
    });
    const { user, metatrader } = response.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        metatrader
      }
    });
  };

  const logout = async () => {
    await logoutFirebase();
    localStorage.removeItem('trade.token');
    dispatch({
      type: 'INITIALIZE',
      payload: {
        ...initialState,
        isInitialized: false
      }
    });
    if (window.electron) {
      window.electron?.store.delete('token');
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (!user) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              ...initialState,
              isInitialized: false
            }
          });
          return;
        }

        const { data } = await accountTradeApi();
        await handleTradeData(user, data);
      } catch (error) {
        console.log('error', error);
        await handleAuthError(error);
      }
    });

    return () => unsubscribe();
  }, [index]);

  const reState = async () => {
    await logout();
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        isInitialized: false,
        metatrader: [],
        activeAccount: null,
        isOtp: false
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        reState,
        login,
        logout,
        register,
        authWithToken,
        getFirebaseCustom
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
