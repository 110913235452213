import { metadata } from './metadata';
import { _processHistoryResponseInRange } from './datafeed.v2';
import { serverApiConfig } from '../config';
import { SymbolClient } from '../grpc/generated/symbol_grpc_web_pb';
import { LastTickRequest } from '../grpc/generated/symbol_pb';
import emitter from '../utilities/emitter';
import { getGrpcResponse } from '../utilities/getGrpcResponse';

const { HistoryClient } = require('../grpc/generated/history_grpc_web_pb.js');

export const useHistoryData = async ({
  request,
  onHistoryCallback,
  resolution,
  periodParams,
  firstDataRequest,
  symbol,
  lastBarsCache
}) => {
  let historyClient = new HistoryClient(serverApiConfig(), null, null);
  let symbolClient = new SymbolClient(
    serverApiConfig(localStorage.getItem('trade.server')),
    null,
    null
  );
  const lastTickRequest = new LastTickRequest();
  lastTickRequest.setSymbol(symbol);

  await historyClient.getOHLC(request, metadata(), async (err, response) => {
    if (response === null) {
      setTimeout(() => {
        onHistoryCallback([], { noData: true });
      }, 0);
    } else {
      const result = _processHistoryResponseInRange(resolution, response.u[0], periodParams);
      if (periodParams.firstDataRequest) {
        if (firstDataRequest) {
          const key = symbol + resolution;

          const lastBar = lastBarsCache.get(key);
          if (lastBar !== undefined) {
            lastBarsCache.delete(key);
          }
          lastBarsCache.set(key, {
            close: result.bars[result.bars.length - 1]?.close,
            high: result.bars[result.bars.length - 1]?.high,
            low: result.bars[result.bars.length - 1]?.low,
            open: result.bars[result.bars.length - 1]?.open,
            timeStamp: result.bars[result.bars.length - 1]?.time
          });
        }
      }

      setTimeout(() => {
        onHistoryCallback(result.bars, result.meta);
      }, 0);

      await symbolClient.getLastTick(lastTickRequest, metadata(), (err, response) => {
        try {
          const bid = getGrpcResponse(response)[1];
          const ask = getGrpcResponse(response)[2];
          const symbol = getGrpcResponse(response)[9];

          let bidAsk = {
            bid,
            ask,
            symbol
          };
          emitter.instance.emit('BidAsk', bidAsk);
        } catch (err) {
          console.log(err);
        }
      });
    }
  });
};
