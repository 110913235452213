import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CalendarIcon from '../icons/CalendarIcon';
const DateInput = memo(({ label, inputRef, onChange, ...props }) => (
  <div>
    <div className="text-left text-[#999999] mb-2 text-sm">{label}</div>
    <div className="relative">
      <input
        ref={inputRef}
        onChange={({ target: { value } }) => onChange(value)}
        className="w-full h-[40px] rounded-md px-2 text-[#c7c8c9] bg-[#0000] border border-[#2e2e2e] text-sm"
        type="date"
        {...props}
      />
      <div
        className="absolute right-[15px] top-[12px] cursor-pointer"
        onClick={() => inputRef.current.showPicker()}>
        <CalendarIcon />
      </div>
    </div>
  </div>
));

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  inputRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateInput;
