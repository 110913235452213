import React, { memo, useEffect, useMemo, useState } from 'react';
import ProfitInvest from './ProfitInves.js';
import useAuth from '../../../hooks/useAuth.js';
import AccountSteam from '../../../data/account.js';
let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const AccountInfo = memo(() => {
  const request = new google_protobuf_empty_pb.Empty();
  const { metatrader, activeAccount } = useAuth();

  const metaAccount = useMemo(() => {
    return metatrader.reduce((a, b) => {
      return {
        ...a,
        [b.id]: b
      };
    }, {});
  }, [metatrader]);

  const token = localStorage.getItem('trade.token');
  const metadata = { Authorization: 'Bearer ' + token };
  const accountSteam = new AccountSteam();

  useEffect(() => {
    accountSteam.subscribeAccount(request, metadata);
    return () => {
      accountSteam.stream?.cancel();
    };
  }, []);

  if (metaAccount) {
    return (
      <div
        className="section-profile"
        onClick={() => {
          accountSteam.stream?.cancel();
        }}>
        <div className="relative">
          <ProfitInvest
            isDemo={metaAccount[activeAccount]?.type === 'demo'}
            currency={metaAccount[activeAccount]?.currency}
          />
        </div>
      </div>
    );
  }
  return <div />;
});

AccountInfo.displayName = 'AccountInfo';

export default AccountInfo;
