import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
const resources = {
  en: {
    translation: {
      head:
        'Trade Responsibly: CFDs are complex instruments and come with a high risk of losing\n' +
        'money rapidly due to leverage. <bi>  67.83% of retail investor accounts lose money when trading CFDs with this provider. </bi>  You should consider whether you understand how CFDs work and whether you can afford to\n' +
        'take the high risk of losing your money.',
      register: {
        title: 'Register Account',
        country: 'Country of residence',
        email: 'Email',
        password: 'Password',
        term: 'You agree to the <bi> IUX Terms and policies </bi>',
        submit: 'Submit',
        hasAccount: 'Already have an account?',
        login: 'Login'
      },
      login: {
        title: 'Login',
        subtitle: "You don't have an account?",
        register: 'Register',
        email: 'Email',
        password: 'Password',
        forgotPassword: 'Forgot your password?',
        login: 'Login',
        desc: 'Trading CFDs and leveraged products generally involves a high risk of loss and you may lose all your investment.'
      },
      otp: {
        title: 'Verification Required',
        verify: {
          word: `<span>Enter the code that was sent to</span> <bi>{{email}}</bi>`,
          ref: `<span>Ref :</span> <bi>{{ref}}</bi>`,
          count: `<span>Haven’t Received it? Resend code in</span> <bi>{{count}}</bi>`
        }
      },
      footer_jsfa_1:
        'IUX Trading Limited is incorporated in Saint Lucia under registration number 2023-00442 at Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia. The physical address of the company is at Office 2, 26 Pittalou str., P.C. 3117, Limassol, Cyprus.',
      footer_jsfa_2:
        'Risk Warning: IUX Trading Limited does not provide services to citizens or residents of certain countries, including but not limited to the United States of America, the United Kingdom, Iceland, Liechtenstein, Norway, the Philippines, Switzerland, North Korea, Myanmar, Iran and residents of the European Union. Individuals accessing this website or its services are responsible for ensuring their usage complies with local laws. IUX Trading Limited does not guarantee that the website information or service is suitable for all countries. The information on this site is not directed at residents in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.',
      footer_jsfa_3:
        'Trading in CFDs carries a high level of risk. Please ensure you fully understand the risks involved, and seek independent advice if necessary.',
      footer_jsfa_4:
        'IUX Trading Limited is not under the supervision of the JFSA, it is not involved with any acts considered to be offering financial products and solicitation for financial services, and this website is not aimed at residents in Japan.',
      footer_jsfa_5: `By registering the account, you acknowledge that you are doing account voluntarily and without any solicitation from&nbsp;<a href="https://www.iuxtrade.com" class="text-[#1cb894]">iuxtrade.com</a>.`,
      desc_1:
        'IUX Trading Limited is incorporated in Saint Lucia under registration number 2023-00442 at Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia. The physical address of the company is at Office 2, 26 Pittalou str., P.C. 3117, Limassol, Cyprus. ',
      desc_2:
        'Risk Warning: IUX Trading Limited does not provide services to citizens or residents of certain countries, including but not limited to the United States of America, the United Kingdom, Iceland, Liechtenstein, Norway, the Philippines, Switzerland, North Korea, Myanmar, Iran and residents of the European Union. Individuals accessing this website or its services are responsible for ensuring their usage complies with local laws. IUX Trading Limited does not guarantee that the website information or service is suitable for all countries. The information on this site is not directed at residents in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.',
      desc_3:
        'Trading in CFDs carries a high level of risk. Please ensure you fully understand the risks involved, and seek independent advice if necessary.',
      desc_4:
        'IUX Trading Limited is not under the supervision of the JFSA, it is not involved with any acts considered to be offering financial products and solicitation for financial services, and this website is not aimed at residents in Japan.',
      desc_5:
        'By registering the account, you acknowledge that you are doing account voluntarily and without any solicitation from <bi>iuxtrade.com</bi>.'
    }
  },
  ja: {
    translation: {
      head: '責任を持って貿易：CFDは複雑な楽器であり、レバレッジのために急速にお金を失うリスクが高くなります。<bi> このプロバイダーとCFDを取引すると、小売投資家アカウントの67.83％がお金を失います。 </bi> CFDがどのように機能するか、そしてお金を失うリスクを冒す余裕があるかどうかを理解する必要があります。',
      register: {
        title: 'アカウント登録',
        country: '居住国 *',
        email: 'Eメール *',
        password: 'パスワード *',
        term: 'IUX市場に同意します <bi> 用語とポリシー。 </bi>',
        submit: '提出する',
        hasAccount: 'すでにアカウントをお持ちですか？',
        login: 'ログイン'
      },
      login: {
        title: 'ログイン',
        subtitle: 'アカウントを持っていないのですか？',
        register: 'アカウントを開きます',
        email: 'あなたのメール',
        password: 'パスワード',
        forgotPassword: 'パスワードをお忘れですか？',
        login: 'ログイン',
        desc: 'CFDとレバレッジされた製品を取引するには、一般に損失のリスクが高く、すべての投資を失う可能性があります。'
      },
      otp: {
        title: '確認が必要',
        verify: {
          word: `<bi>{{email}}</bi> <span>に送信されたコードを入力してください。</span>`,
          ref: `<span>参照 :</span> <bi>{{ref}}</bi>`,
          count: `<span>受け取っていませんか?</span> <bi>{{count}}</bi> <span>以内にコードを再送信してください。</span>`
        }
      },
      footer_jsfa_1:
        'IUX Trading Limited は、セントルシアのグロス アイレット、ロドニー ベイ、ロドニー ビレッジ、ザ サザビー ビル グラウンド フロアに登録番号 2023-00442 で設立されました。会社の住所は、Office 2、26 Pittalou str.、P.C. 3117、リマソール、キプロスです。',
      footer_jsfa_2:
        'リスク警告: IUX Trading Limited は、米国、英国、アイスランド、リヒテンシュタイン、ノルウェー、フィリピン、スイス、北朝鮮、ミャンマー、イラン、および欧州連合の居住者を含むがこれらに限定されない特定の国の国民または居住者にサービスを提供しません。このウェブサイトまたはそのサービスにアクセスする個人は、使用が現地の法律に準拠していることを確認する責任があります。IUX Trading Limited は、ウェブサイトの情報またはサービスがすべての国に適していることを保証しません。このサイトの情報は、そのような配布または使用が現地の法律または規制に違反する国または管轄区域の居住者を対象としたものではありません。',
      footer_jsfa_3:
        'CFD取引には高いリスクが伴います。関連するリスクを十分に理解し、必要に応じて独立したアドバイスを求めてください。',
      footer_jsfa_4:
        'IUX Trading Limitedは日本金融庁(JFSA)の監督下にはなく、金融商品や金融サービスの勧誘に該当する行為には関与していません。また、本ウェブサイトは日本国内の居住者を対象としたものではありません。',
      footer_jsfa_5: `口座を登録することで、お客様は&nbsp;<a href="https://www.iuxtrade.com" class="text-[#1cb894]">iuxtrade.com</a>&nbsp;からの勧誘を受けることなく、自発的に口座を作成していることを認めるものとします。`,
      desc_1:
        'IUX Trading Limited は、セントルシアのグロス アイレット、ロドニー ベイ、ロドニー ビレッジ、ザ サザビー ビル グラウンド フロアに登録番号 2023-00442 で設立されました。会社の住所は、Office 2、26 Pittalou str.、P.C. 3117、リマソール、キプロスです。',
      desc_2:
        'リスク警告: IUX Trading Limited は、米国、英国、アイスランド、リヒテンシュタイン、ノルウェー、フィリピン、スイス、北朝鮮、ミャンマー、イラン、および欧州連合の居住者を含むがこれらに限定されない特定の国の国民または居住者にサービスを提供しません。このウェブサイトまたはそのサービスにアクセスする個人は、使用が現地の法律に準拠していることを確認する責任があります。IUX Trading Limited は、ウェブサイトの情報またはサービスがすべての国に適していることを保証しません。このサイトの情報は、そのような配布または使用が現地の法律または規制に違反する国または管轄区域の居住者を対象としたものではありません。',
      desc_3:
        'CFD取引には高いリスクが伴います。関連するリスクを十分に理解し、必要に応じて独立したアドバイスを求めてください。',
      desc_4:
        'IUX Trading Limitedは日本金融庁(JFSA)の監督下にはなく、金融商品や金融サービスの勧誘に該当する行為には関与していません。また、本ウェブサイトは日本国内の居住者を対象としたものではありません。',
      desc_5:
        '口座を登録することで、お客様は <bi>iuxtrade.com</bi> からの勧誘を受けることなく、自発的に口座を作成していることを認めるものとします。'
    }
  }
};

// Initialize i18n
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('i18nextLng'), // Default language
  fallbackLng: 'ja',
  interpolation: {
    escapeValue: false // React already escapes values to prevent XSS
  }
});

export default i18n;
