import moment from 'moment-timezone';
import { SymbolClient, SymbolName, TickRequest } from '../grpc/generated/symbol_grpc_web_pb';
import { OHLCRequest } from '../grpc/generated/history_pb';
import { getMenuFromLocalstorage } from '../utilities/fromStorage';
import { serverApiConfig } from '../config';
import { metadata } from './metadata';
import { getTimeframeInSecond, periodRange, shiftDST, shouldShift } from './datafeed.v2';
import { useHistoryData } from './useHistoryData';
import emitter from '../utilities/emitter';
import { getGrpcResponse } from '../utilities/getGrpcResponse';

// Constants
const SUPPORTED_RESOLUTIONS = ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'];
const DEFAULT_TIMEZONE = 'Europe/London';
const MAX_HISTORY_YEARS = 10;

class TradingDataFeed {
  constructor({ onSubscribe, getLastTick }) {
    this.lastBarsCache = new Map();
    this.symbolsList = [];
    this.currentSymbol = '';
    this.previousSymbol = null;
    this.onSubscribe = onSubscribe.onSubscribe;
    this.getLastTick = getLastTick;
  }

  // Configuration
  static configurationData = {
    supports_search: true,
    supports_group_request: false,
    supports_marks: true,
    supports_timescale_marks: true,
    supports_time: true,
    supported_resolutions: SUPPORTED_RESOLUTIONS,
    exchanges: [
      {
        value: 'iux',
        name: 'iux',
        desc: 'IUX Markets Exchange'
      }
    ],
    symbols_types: [
      {
        name: 'crypto',
        value: 'crypto'
      }
    ]
  };

  // Helper methods
  getSymbolFromURL() {
    const active = getMenuFromLocalstorage();
    return active?.active;
  }

  getInstruments() {
    const fromStorage = localStorage.getItem('symbols.trading.data');
    return JSON.parse(fromStorage) ?? [];
  }

  createSymbolInfo(item) {
    const priceScale = 10 ** item?.digit;
    return {
      ticker: item?.full_name,
      name: item?.symbol,
      description: item?.description,
      type: item?.type,
      session: '24x7',
      timezone: DEFAULT_TIMEZONE,
      exchange: item?.exchange,
      minmov: 1,
      pricescale: priceScale,
      has_intraday: true,
      supported_resolutions: SUPPORTED_RESOLUTIONS
    };
  }

  // Interface methods
  async onReady(callback) {
    this.currentSymbol = this.getSymbolFromURL();
    this.previousSymbol = this.currentSymbol;
    this.symbolsList = this.getInstruments();
    setTimeout(() => callback(TradingDataFeed.configurationData));
  }

  async searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    const symbols = [];
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name
        .toLowerCase()
        .includes(userInput.toLowerCase());
      return isExchangeValid && isFullSymbolContainsInput;
    });
    onResultReadyCallback(newSymbols);
  }

  async resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    try {
      const item = this.symbolsList.find((e) => e.symbol === symbolName);
      const symbolInfo = this.createSymbolInfo(item);
      setTimeout(() => onSymbolResolvedCallback(symbolInfo));
    } catch (error) {
      console.error('Symbol resolution error:', error);
      setTimeout(() => onResolveErrorCallback('Cannot resolve symbol'));
    }
  }

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    try {
      const request = this.createHistoryRequest(symbolInfo, resolution, periodParams);
      await useHistoryData({
        onHistoryCallback,
        request,
        resolution,
        periodParams,
        symbol: symbolInfo.name,
        firstDataRequest: periodParams.firstDataRequest,
        lastBarsCache: this.lastBarsCache
      });
    } catch (error) {
      console.error('Get bars error:', error);
      setTimeout(() => onErrorCallback(error));
    }
  }

  createHistoryRequest(symbolInfo, resolution, periodParams) {
    const request = new OHLCRequest();
    let { from, to } = periodParams;

    if (shouldShift(resolution)) {
      from = shiftDST(from);
      to = shiftDST(to);
      request.setFrom(moment.unix(from).add(-5, 'days').unix());
    } else {
      const limitFrom = moment().utc().subtract(MAX_HISTORY_YEARS, 'years').startOf('day').unix();
      from = Math.max(from, limitFrom);
      request.setFrom(from);
    }

    request.setSymbol(symbolInfo.name);
    request.setTo(to);
    request.setTimeFrame(getTimeframeInSecond(resolution));
    return request;
  }

  async subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID) {
    const request = new TickRequest();
    const symbol = new SymbolName();
    this.getLastTick(symbolInfo.name);

    symbol.setName(symbolInfo.name);
    request.addSymbols(symbol);

    this.onSubscribe(
      request,
      metadata(),
      resolution,
      onRealtimeCallback,
      symbolInfo.name,
      this.lastBarsCache,
      this.previousSymbol
    );

    this.setupBarUpdateListener(symbolInfo, resolution, onRealtimeCallback);
  }

  setupBarUpdateListener(symbolInfo, resolution, onRealtimeCallback) {
    emitter.instance.on('bar', (data) => {
      if (getGrpcResponse(data)[9] !== symbolInfo.name) return;

      const key = symbolInfo.name + resolution;
      const lastDailyBar = this.lastBarsCache.get(key);
      if (!lastDailyBar) return;

      const bar = this.createUpdatedBar(data, lastDailyBar, resolution);
      this.lastBarsCache.set(key, { ...bar, timeStamp: bar.time });
      setTimeout(() => onRealtimeCallback(bar));
    });
  }

  createUpdatedBar(data, lastDailyBar, resolution) {
    const time = periodRange(resolution, getGrpcResponse(data)[0]) * 1000;
    const currentPrice = getGrpcResponse(data)[1];

    if (time > lastDailyBar.timeStamp) {
      return {
        time,
        open: currentPrice,
        high: currentPrice,
        low: currentPrice,
        close: currentPrice
      };
    }

    return {
      time: lastDailyBar.timeStamp,
      open: lastDailyBar.open,
      high: Math.max(lastDailyBar.high, currentPrice),
      low: Math.min(lastDailyBar.low, currentPrice),
      close: currentPrice
    };
  }

  async unsubscribeBars(subscriberUID) {
    const symbolUnsub = subscriberUID.slice(0, subscriberUID.indexOf('_'));
    const key = symbolUnsub + subscriberUID.split('#')[1].replace('_', '');

    if (symbolUnsub === this.previousSymbol) return;

    const symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );

    const request = new SymbolName();
    request.setName(symbolUnsub);

    symbolClient.tickUnsubscribe(request, metadata(), (err, response) => {
      if (response === null) return err;
      this.lastBarsCache.delete(key);
    });
  }
}

export default function createDataFeed({ onSubscribe, getLastTick }) {
  const feed = new TradingDataFeed({ onSubscribe, getLastTick });
  return {
    onReady: feed.onReady.bind(feed),
    searchSymbols: feed.searchSymbols.bind(feed),
    resolveSymbol: feed.resolveSymbol.bind(feed),
    getBars: feed.getBars.bind(feed),
    subscribeBars: feed.subscribeBars.bind(feed),
    unsubscribeBars: feed.unsubscribeBars.bind(feed)
  };
}
