import React, { useContext, useEffect } from 'react';
import { checkMinMaxVolume } from '../../../utilities/instrumentInfo';
import { TradeContext } from '../../trading-dialog/context/TradeContextProvider';

const QuickLotSize = () => {
  const { instrumentInfo, lotSize, setLotSize, handleLotSize, inputLotSize, validateLotSize } =
    useContext(TradeContext);

  useEffect(() => {
    if (instrumentInfo) {
      setLotSize(instrumentInfo?.volume_min / 10000);
    }
  }, [instrumentInfo]);

  return (
    <div className="box-lotsize">
      <span className="text-lebel">Lot size</span>
      <input
        className="lot-size"
        onChange={({ target: { value } }) => inputLotSize(value)}
        onBlur={({ target: { value } }) => validateLotSize(value)}
        type="number"
        value={lotSize.toLocaleString()}
      />
      <div className="lotsite-panel">
        <button
          className="lotsit-button-up"
          disabled={checkMinMaxVolume('max', lotSize, instrumentInfo?.volume_max).con}
          onClick={() => handleLotSize('dec')}>
          +
        </button>
        <button
          className="lotsit-button-down"
          disabled={lotSize === 0}
          onClick={() => handleLotSize('inc')}>
          -
        </button>
      </div>
    </div>
  );
};

export default QuickLotSize;
