import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../components/guards/AuthGuard';
import GuestGuard from '../components/guards/GuestGuard';
import { Login } from '../pages/login';
import Dashboard from '../pages/dashboard';

export default function Router() {
  return useRoutes([
    {
      path: 'login',
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true
        },
        {
          path: ':token',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: 'trade-panel',
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      )
    },
    { path: '*', element: <Navigate to="/login" replace /> }
  ]);
}
