export const usePriceChange = ({ type, price_order, ask, digits = 2 }) => {
  // Input validation
  if (typeof digits !== 'number' || digits < 0) {
    digits = 2; // Default to 2 digits if invalid
  }

  // Handle null/undefined price_order
  if (!price_order || price_order === 0) {
    return ask.toFixed(digits);
  }

  const digitsOfSymbol = `${(0).toFixed(digits).substring(0, (0).toFixed(digits).length - 1)}1`;
  const numericPrice = +price_order;

  switch (type) {
    case 'dec':
      return (numericPrice - parseFloat(digitsOfSymbol)).toFixed(digits);
    case 'inc':
      return (numericPrice + parseFloat(digitsOfSymbol)).toFixed(digits);
    default:
      return numericPrice.toFixed(digits);
  }
};

export const usePriceTriggerChange = ({ type, price_trigger, ask, digits = 2 }) => {
  // Input validation
  if (typeof digits !== 'number' || digits < 0) {
    digits = 2; // Default to 2 digits if invalid
  }

  // Handle null/undefined price_trigger
  if (!price_trigger || price_trigger === 0) {
    return (ask || 0).toFixed(digits);
  }

  const digitsOfSymbol = `${(0).toFixed(digits).substring(0, (0).toFixed(digits).length - 1)}1`;
  const numericPrice = +price_trigger;

  switch (type) {
    case 'dec':
      return (numericPrice - parseFloat(digitsOfSymbol)).toFixed(digits);
    case 'inc':
      return (numericPrice + parseFloat(digitsOfSymbol)).toFixed(digits);
    default:
      return numericPrice.toFixed(digits);
  }
};

export const handlePrice = ({ type, index, payload, bidAsk, setPayload, digits = 2 }) => {
  // Input validation
  if (typeof digits !== 'number' || digits < 0) {
    digits = 2;
  }

  const getCalcDigit = (digits) => {
    const zero = 0;
    return `${zero.toFixed(digits).substring(0, zero.toFixed(digits).length - 1)}1`;
  };

  const updatePrice = (currentPrice, bidAsk, type, digits) => {
    if (currentPrice === 0) {
      return parseFloat((bidAsk?.ask || 0).toFixed(digits));
    }

    const calcDigit = parseFloat(getCalcDigit(digits));
    const numericPrice = +currentPrice;

    return type === 'dec'
      ? (numericPrice - calcDigit).toFixed(digits)
      : (numericPrice + calcDigit).toFixed(digits);
  };

  const priceKey = index === 0 ? 'price_order' : 'price_trigger';
  const currentPrice = payload[priceKey];

  setPayload((prev) => ({
    ...prev,
    [priceKey]: updatePrice(currentPrice, bidAsk, type, digits)
  }));
};
