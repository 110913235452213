import ChartUtils from './ChartUtils';

class PriceLineManager {
  constructor(tvWidget) {
    this.tvWidget = tvWidget;
  }

  updatePriceLines(symbol, data) {
    const activeChart = this.tvWidget.activeChart();
    const shapes = activeChart.getAllShapes();
    const existingLines = new Set();

    if (shapes.length > 0) {
      shapes
        .filter((shape) => shape.name === 'horizontal_line')
        .forEach((shape) => {
          const entity = activeChart.getShapeById(shape.id);
          if (!entity) return;

          const text = entity.getProperties().text;
          if (text === 'bid' || text === 'ask') {
            entity.setPoints([
              {
                time: Date.now() / 1000,
                price: data[symbol][text]
              }
            ]);
            existingLines.add(text);
          }
        });
    }

    if (!existingLines.has('ask') || !existingLines.has('bid')) {
      this.createNewPriceLines(symbol, data);
    }
  }

  createNewPriceLines(symbol, data) {
    const now = Date.now() / 1000;
    ChartUtils.createHorizontalLine(this.tvWidget, now, data[symbol].ask, 'ask');
    ChartUtils.createHorizontalLine(this.tvWidget, now, data[symbol].bid, 'bid');
  }
}

export default PriceLineManager;
