import { FILTER_PERIODS } from '../constant'
import moment from 'moment/moment'
import { getTimestampForPeriod } from '../utils/getTimestampForPeriod'
import { calculateFinancialMetrics } from './calculateFinancialMetrics'
import { calculateOrderStats } from './calculateOrderStats'


const filterTransactionsByDate = (history, timeValue, timestamp, fromTo, type) => {
  const typeVar = {
    position: (trans, timestamp) => moment(trans.timestamp).isAfter(timestamp),
    order: (trans, timestamp) => moment(trans.time_done).isAfter(timestamp),
    deal: (trans, timestamp) => moment(trans.timestamp).isAfter(timestamp),
  }

  if (timeValue === FILTER_PERIODS.TODAY) {
    return history.filter((entry) => moment(entry.time_format).isSame(timestamp, 'day'))
  }
  if (timeValue === FILTER_PERIODS.CUSTOM && fromTo) {
    return history.filter((transaction) => moment(transaction.time_format).isBetween(fromTo.from, fromTo.to))
  }
  return history.filter((transaction) => typeVar[type](transaction, timestamp))
}

const filterHistory = ({ type, keyValue, history, setInfo, setHistoryFilter, fromTo }) => {
  try {
    const filterValue = Number(keyValue)

    // Get timestamp for selected period
    const timestamp = getTimestampForPeriod(filterValue)

    // Filter transactions
    let filteredTransactions = filterTransactionsByDate(history, filterValue, timestamp, fromTo, type)

    let metrics = {}

    if (type === 'position' || type === 'deal') {
      // Calculate metrics and update state
      metrics = calculateFinancialMetrics(filteredTransactions)
    }

    if (type === 'order') {
      // Calculate metrics and update state
      metrics = calculateOrderStats(filteredTransactions)
    }

    // Handle custom date range
    if (filterValue === FILTER_PERIODS.CUSTOM && fromTo) {

      const filtered = history.filter((e) => moment(e.time_format).isBetween(fromTo.from, fromTo.to))
      filteredTransactions = filterTransactionsByDate(filtered, filterValue, timestamp, fromTo, type)

      if (type === 'position' || type === 'deal') {
        // Calculate metrics and update state
        metrics = calculateFinancialMetrics(filteredTransactions)
      }

      if (type === 'order') {
        // Calculate metrics and update state
        metrics = calculateOrderStats(filteredTransactions)
      }

      setHistoryFilter((prev) => ({
        ...prev,
        index: filterValue,
        data: [...filtered].reverse(),
      }))
      setInfo(metrics)
      return
    }
    
    setInfo(metrics)
    setHistoryFilter((prev) => ({
      index: filterValue,
      data: [...filteredTransactions].reverse(),
    }))
  } catch (error) {
    console.error('Error in filterHistory:', error)
    // You might want to add proper error handling here
  }
}

export default filterHistory