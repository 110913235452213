import PositionSteam from '../../../data/steamPosition'
import React, { useEffect } from 'react'
import google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb'
import { metadata } from '../../../data/metadata'
import Orders from '../order'
import Position from './List'

const PositionTable = ({ type }) => {
  const { subscribePosition } = PositionSteam()

  const request = new google_protobuf_empty_pb.Empty()

  window.addEventListener('contextmenu', (event) => {
    if (event.button === 2) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    (async () => {
      await subscribePosition(request, metadata())
    })()
  }, [])

  if (type === 'port') {
    return (
      <div
        style={{
          transform: type === 'port' ? 'translateX(0%)' : 'translateX(-120%)',
          position: 'fixed',
          left: '70px',
          top: '65px',
        }}
        id="positionsEle">
        <div className={`position-dialog`}>
          <div className="header">
            <div className="grid grid-cols-[20%_55%_25%] flex-header">
              <p>Position</p>
              <div className="flex items-center gap-2">
                <p className="mr-9">Symbol</p>
                <p>Type</p>
                <p>Volume</p>
              </div>
              <p className="text-end">PL($)</p>
            </div>
          </div>
          <div id="custom-order-scroll" className={type === 'port' ? 'table-position-scroll' : ''}>
            <Position />
            <Orders />
          </div>
        </div>
      </div>
    )
  } else {
    return <div />
  }
}

PositionTable.displayName = 'PositionTable'

export default PositionTable
