import { ORDER_STATES } from '../constant'

export const calculateOrderStats = (filtered) => {
  const countByState = (state) => filtered.filter((entry) => entry.state === state).length

  return {
    failed: countByState(ORDER_STATES.FAILED),
    canceled: countByState(ORDER_STATES.CANCELED),
    expired: countByState(ORDER_STATES.EXPIRED),
    total: filtered.filter((entry) => entry.state !== undefined).length,
  }
}