import React, { memo, useEffect, useMemo, useState } from 'react';
import TradeFunction from './TradeFunction';
import { makeInstrumentByIdSelector } from '../../../redux/selector';
import { useAppDispatch, useAppSelector } from '../../../redux/app';
import BuyStopFunction from '../buy-sell-stop';
import BuyLimitFunction from '../buy-sell-limit';
import BuyStopLimitFunction from '../buy-sell-stop-limit';
import { setPayload } from '../../../redux/reducer/payload';

const TradeDialog = memo(({ type }) => {
  const active = useAppSelector((state) => state.symbol?.active);
  const payload = useAppSelector((state) => state.payload?.payload);
  const getSymbol = useMemo(makeInstrumentByIdSelector, []);
  const symbol = useAppSelector((state) => getSymbol(state, active));
  const [marketOption, setMarketOption] = useState(1);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (open) {
  //     setTimeout(() => {
  //       setLoading(open);
  //     }, 200);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [open]);

  const toggle = () => {
    setOpen(!open);
  };

  const ExecMode = {
    0: 'Request Execution',
    1: 'Instant Execution',
    2: 'Market Execution',
    3: 'Exchange Execution'
  };

  const List = useMemo(() => {
    if (symbol) {
      return [
        ExecMode[symbol.exec_mode],
        'Buy Limit',
        'Sell Limit',
        'Buy Stop',
        'Sell Stop',
        'Buy Stop Limit',
        'Sell Stop Limit'
      ];
    }
    return [
      'Market Execution',
      'Buy Limit',
      'Sell Limit',
      'Buy Stop',
      'Sell Stop',
      'Buy Stop Limit',
      'Sell Stop Limit'
    ];
  }, [symbol]);

  const marketOptions = (type) => {
    switch (type) {
      case 1:
        return <TradeFunction />;
      case 2:
        return <BuyLimitFunction isBuyLimit />;
      case 3:
        return <BuyLimitFunction />;
      case 4:
        return <BuyStopFunction isBuyStop />;
      case 5:
        return <BuyStopFunction />;
      case 6:
        return <BuyStopLimitFunction isBuyStopLimit />;
      case 7:
        return <BuyStopLimitFunction />;
      default:
        return <div />;
    }
  };

  return (
    <div
      style={{
        transform: type === 'trade' ? 'translateX(0%)' : 'translateX(-125%)',
        position: 'fixed',
        left: '70px',
        top: '65px'
      }}>
      <div className="trade-dialog">
        <div className="trade-dialog-header">
          <div className="relative w-full flex flex-col">
            <div className="flex justify-between items-center cursor-pointer" onClick={toggle}>
              <div className="text-white text-sm">{List[marketOption - 1]}</div>
              <div>
                <svg
                  className={`transition-all ${open ? 'rotate-0' : 'rotate-180'}`}
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    {' '}
                    <path
                      d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                      fill="#fff"></path>{' '}
                  </g>
                </svg>
              </div>
            </div>
            {open && (
              <div
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={toggle}
                className="absolute top-[42px] left-0 bg-[#1E1E1E] border border-[#80808059] w-full flex flex-col rounded-md">
                {List.map((e, i) => (
                  <div
                    onClick={() => {
                      setMarketOption(i + 1);
                      toggle();
                      dispatch(
                        setPayload({
                          ...payload,
                          price_order: 0,
                          price_trigger: 0
                        })
                      );
                    }}
                    key={e}
                    className="text-white text-sm px-4 h-[30px] flex items-center hover:bg-[#169376] hover:rounded-md transition-all cursor-pointer">
                    {e}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {type === 'trade' && marketOptions(marketOption)}
      </div>
    </div>
  );
});

TradeDialog.displayName = 'TradeDialog';

export default TradeDialog;
