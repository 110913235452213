import { ORDER_FIELDS, ORDER_STATES } from '../constant';
import moment from 'moment';

export const mapOrdersToHistory = (orders) => {
  return orders.map((item) => ({
    order: item[ORDER_FIELDS.ORDER],
    external_id: item[ORDER_FIELDS.EXTERNAL_ID],
    login: item[ORDER_FIELDS.LOGIN],
    dealer: item[ORDER_FIELDS.DEALER],
    symbol: item[ORDER_FIELDS.SYMBOL],
    digits: item[ORDER_FIELDS.DIGITS],
    digits_currency: item[ORDER_FIELDS.DIGITS_CURRENCY],
    contract_size: item[ORDER_FIELDS.CONTRACT_SIZE],
    state: item[ORDER_FIELDS.STATE],
    reason: item[ORDER_FIELDS.REASON],
    time_setup: item[ORDER_FIELDS.TIME_SETUP],
    time_expiration: item[ORDER_FIELDS.TIME_EXPIRATION],
    time_done: item[ORDER_FIELDS.TIME_DONE],
    type: item[ORDER_FIELDS.TYPE] ?? 0,
    type_fill: item[ORDER_FIELDS.TYPE_FILL],
    type_time: item[ORDER_FIELDS.TYPE_TIME],
    price_order: item[ORDER_FIELDS.PRICE_ORDER],
    price_trigger: item[ORDER_FIELDS.PRICE_TRIGGER],
    price_current: item[ORDER_FIELDS.PRICE_CURRENT],
    price_sl: item[ORDER_FIELDS.PRICE_SL],
    price_tp: item[ORDER_FIELDS.PRICE_TP],
    volume_initial: item[ORDER_FIELDS.VOLUME_INITIAL],
    volume_current: item[ORDER_FIELDS.VOLUME_CURRENT],
    expert_id: item[ORDER_FIELDS.EXPERT_ID],
    position_id: item[ORDER_FIELDS.POSITION_ID],
    comment: item[ORDER_FIELDS.COMMENT],
    time_setup_msc: item[ORDER_FIELDS.TIME_SETUP_MSC],
    time_done_msc: item[ORDER_FIELDS.TIME_DONE_MSC],
    rate_margin: item[ORDER_FIELDS.RATE_MARGIN],
    time_format: moment.unix(item[ORDER_FIELDS.TIME_DONE]).format('YYYY-MM-DD')
  }));
};

export const filterTodayOrders = (history) => {
  const today = moment();
  return history.filter((entry) => moment(entry.time_format).isSame(today, 'day'));
};

export const calculateOrderStats = (orders) => {
  const countByState = (state) => orders.filter((order) => order.state === state).length;

  return {
    failed: countByState(ORDER_STATES.FAILED),
    canceled: countByState(ORDER_STATES.CANCELED),
    expired: countByState(ORDER_STATES.EXPIRED),
    total: orders.filter((order) => order.state !== undefined).length
  };
};

const processOrders = (orders, setInfo, setHistory, setOrderFilter) => {
  if (!orders?.length) return;

  const history = mapOrdersToHistory(orders);
  const todayOrders = filterTodayOrders(history);
  const orderStats = calculateOrderStats(todayOrders);

  updateState(history, todayOrders, orderStats, setInfo, setHistory, setOrderFilter);
};

const updateState = (
  history,
  todayOrders,
  orderStats,
  setInfo,
  setHistory,
  setOrderFilter
) => {
  setInfo(orderStats);
  setHistory(history);
  setOrderFilter({
    index: 7,
    data: [...todayOrders].reverse()
  });
};

export default processOrders;
