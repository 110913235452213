import React from 'react';
export const svgEle = {
  Stock: (
    <div className="w-[25px] h-[25px] rounded-[50%] bg-[#898c97] flex">
      <svg
        className="m-auto"
        width="15"
        height="15"
        // fill="#0e0e0e"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6092 8.34165L12.0001 3.64575L3.39093 8.34165L3.75007 9.75007H5.25007V15.7501H4.50007V17.2501H19.5001V15.7501H18.7501V9.75007H20.2501L20.6092 8.34165ZM6.75007 15.7501V9.75007H9.00007V15.7501H6.75007ZM10.5001 15.7501V9.75007H13.5001V15.7501H10.5001ZM15.0001 15.7501V9.75007H17.2501V15.7501H15.0001ZM12.0001 5.35438L17.3088 8.25007H6.69131L12.0001 5.35438ZM3 19.5001H21V18.0001H3V19.5001Z"
            fill="#0e0e0e"></path>{' '}
        </g>
      </svg>
    </div>
  ),
  'Forex Minor': (
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.31673C9.61957 6.60867 8.25 7.83361 8.25 9.5C8.25 11.4172 10.0628 12.75 12 12.75C13.3765 12.75 14.25 13.6557 14.25 14.5C14.25 15.3443 13.3765 16.25 12 16.25C10.6235 16.25 9.75 15.3443 9.75 14.5C9.75 14.0858 9.41421 13.75 9 13.75C8.58579 13.75 8.25 14.0858 8.25 14.5C8.25 16.1664 9.61957 17.3913 11.25 17.6833V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.6833C14.3804 17.3913 15.75 16.1664 15.75 14.5C15.75 12.5828 13.9372 11.25 12 11.25C10.6235 11.25 9.75 10.3443 9.75 9.5C9.75 8.65573 10.6235 7.75 12 7.75C13.3765 7.75 14.25 8.65573 14.25 9.5C14.25 9.91421 14.5858 10.25 15 10.25C15.4142 10.25 15.75 9.91421 15.75 9.5C15.75 7.83361 14.3804 6.60867 12.75 6.31673V6Z"
          fill="#898c97"></path>{' '}
      </g>
    </svg>
  ),
  'Forex Major': (
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.31673C9.61957 6.60867 8.25 7.83361 8.25 9.5C8.25 11.4172 10.0628 12.75 12 12.75C13.3765 12.75 14.25 13.6557 14.25 14.5C14.25 15.3443 13.3765 16.25 12 16.25C10.6235 16.25 9.75 15.3443 9.75 14.5C9.75 14.0858 9.41421 13.75 9 13.75C8.58579 13.75 8.25 14.0858 8.25 14.5C8.25 16.1664 9.61957 17.3913 11.25 17.6833V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.6833C14.3804 17.3913 15.75 16.1664 15.75 14.5C15.75 12.5828 13.9372 11.25 12 11.25C10.6235 11.25 9.75 10.3443 9.75 9.5C9.75 8.65573 10.6235 7.75 12 7.75C13.3765 7.75 14.25 8.65573 14.25 9.5C14.25 9.91421 14.5858 10.25 15 10.25C15.4142 10.25 15.75 9.91421 15.75 9.5C15.75 7.83361 14.3804 6.60867 12.75 6.31673V6Z"
          fill="#898c97"></path>{' '}
      </g>
    </svg>
  ),
  Index: (
    <div className="w-[25px] h-[25px] rounded-[50%] bg-[#898c97] flex">
      <svg
        width="20"
        height="20"
        fill="#0e0e0e"
        viewBox="-7.5 -3.5 24 24"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin"
        className="jam jam-thunder-f m-auto">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M5.708 4.968h1.789a1.5 1.5 0 0 1 1.378 2.09l-3.96 9.243a1.049 1.049 0 0 1-2.012-.413v-6.92L1.45 8.923A1.5 1.5 0 0 1 0 7.423V1.5A1.5 1.5 0 0 1 1.5 0h2.708a1.5 1.5 0 0 1 1.5 1.5v3.468z"></path>
        </g>
      </svg>
    </div>
  ),
  Crypto: (
    <svg
      width="28"
      height="28"
      fill="#898c97"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fillRule="evenodd"
          d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm7.189-17.98c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z"></path>
      </g>
    </svg>
  ),
  'Metals&Energies': (
    <div className="w-[25px] h-[25px] rounded-[50%] bg-[#898c97] flex">
      <svg
        className="m-auto"
        width="12"
        height="12"
        fill="#0e0e0e"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 421.322 421.322"
        xmlSpace="preserve">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <g>
            {' '}
            <g>
              {' '}
              <path d="M317.486,1.426H25c-13.807,0-25,11.193-25,25c0,13.808,11.193,25,25,25l52.993,0.059c7.731,0,14,6.268,14,14 c0,7.732-6.269,14-14,14H33.625v106.178H25c-13.807,0-25,11.191-25,25c0,13.807,11.193,25,25,25l52.993,0.072 c7.731,0,14,6.268,14,14s-6.269,14-14,14H33.625v106.162H25c-13.807,0-25,11.193-25,25s11.193,25,25,25h220.219 c-27.318-22.332-44.79-56.281-44.79-94.236c0-28.013,17.42-65.127,53.258-113.467c24.724-33.35,49.11-59.59,50.137-60.69 l5.038-5.404V51.426h8.625c13.807,0,25-11.192,25-25C342.486,12.619,331.293,1.426,317.486,1.426z"></path>{' '}
              <path d="M376.256,232.588c-21.892-29.528-44.096-53.418-44.317-53.656c-1.132-1.214-2.718-1.903-4.377-1.903 c-1.66,0-3.245,0.689-4.377,1.903c-0.223,0.238-22.428,24.128-44.317,53.656c-29.902,40.334-45.062,71.643-45.062,93.059 c0,51.699,42.06,93.762,93.758,93.762s93.76-42.062,93.76-93.762C421.32,304.229,406.158,272.922,376.256,232.588z M336.925,389.066c-4.914,1.162-9.979,1.75-15.057,1.75c-36.08,0-65.435-29.354-65.435-65.435c0-4.976,1.531-13.986,8.826-28.673 c0.733-1.477,2.423-2.209,4.002-1.739c1.578,0.47,2.592,2.009,2.397,3.646c-0.168,1.414-0.252,2.744-0.252,3.955 c0,38.674,27.506,72.268,65.403,79.877c1.562,0.312,2.693,1.672,2.721,3.264C339.56,387.305,338.475,388.699,336.925,389.066z"></path>{' '}
            </g>{' '}
          </g>{' '}
        </g>
      </svg>
    </div>
  ),
  Thematic: (
    <div className="w-[25px] h-[25px] rounded-[50%] bg-[#898c97] flex">
      <svg
        width="25"
        height="25"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_159_21)">
          {/*<path d="M0 0H100V100H0V0Z" fill="#8A8C97" rx="15" ry="15" />*/}
          <g clipPath="url(#clip1_159_21)">
            <path
              d="M56.7697 59.203C54.8793 60.5872 52.5488 61.4056 50.0263 61.4056C43.7125 61.4056 38.5944 56.2873 38.5944 49.9738C38.5944 44.2578 42.7893 39.5219 48.2684 38.6763V20C32.5135 20.9116 20 33.9918 20 49.9736C20 66.5457 33.4543 80 50.0264 80C57.6794 80 64.6668 77.1289 69.9741 72.4073L56.7697 59.203Z"
              fill="black"
            />
            <path
              d="M61.3236 51.7314C61.0388 53.5764 60.3125 55.2753 59.2565 56.7179L72.4599 69.9213C76.8174 65.0234 79.5971 58.6941 80 51.7314H61.3236Z"
              fill="black"
            />
            <path
              d="M51.7842 38.6763C56.6902 39.4334 60.5667 43.3098 61.3238 48.2159H80C79.1221 33.0427 66.9573 20.8779 51.7842 20V38.6763Z"
              fill="black"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_159_21">
            <rect width="100" height="100" fill="white" />
          </clipPath>
          <clipPath id="clip1_159_21">
            <rect width="60" height="60" fill="white" transform="translate(20 20)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
};
