import { serverApiConfig } from '../config';
import emitter from '../utilities/emitter.jsx';
import { getGrpcResponse } from '../utilities/getGrpcResponse';
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');
const { AccountClient } = require('../grpc/generated/account_grpc_web_pb');

export default class AccountSteam {
  stream = null;
  async subscribeAccount(request, metadata) {
    try {
      let accountClient = new AccountClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      this.stream = accountClient.getAccount(request, metadata);
      this.stream.on('data', function (response) {
        let accountInfo = {
          login: getGrpcResponse(response)[0],
          currency_digits: getGrpcResponse(response)[1],
          balance: getGrpcResponse(response)[2],
          credit: getGrpcResponse(response)[3],
          equity: getGrpcResponse(response)[4],
          margin: getGrpcResponse(response)[5],
          margin_free: getGrpcResponse(response)[6],
          margin_level: getGrpcResponse(response)[7],
          margin_leverage: getGrpcResponse(response)[8],
          profit: getGrpcResponse(response)[9]
        };
        emitter.instance.emit('accountInfo', accountInfo);
      });
      this.stream.on('error', function (err) {
        this.stream?.cancel();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
      this.stream.on('status', function (status) {});
      this.stream.on('end', function () {});
    } catch (e) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }
  async accountInfo(request, metadata) {
    try {
      let accountClient = new AccountClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      this.call = accountClient.account(request, metadata, (err, response) => {
        let accountInfoData = {
          login: getGrpcResponse(response)[0],
          currency_digits: getGrpcResponse(response)[1],
          balance: getGrpcResponse(response)[2],
          credit: getGrpcResponse(response)[3],
          equity: getGrpcResponse(response)[4],
          margin: getGrpcResponse(response)[5],
          margin_free: getGrpcResponse(response)[6],
          margin_level: getGrpcResponse(response)[7],
          margin_leverage: getGrpcResponse(response)[8],
          profit: getGrpcResponse(response)[9]
        };
        emitter.instance.emit('accountInfo', accountInfoData);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
