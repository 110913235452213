import React from 'react';
import CustomScrollTopMenu from './CustomScrollTopMenu';
import TopMenuAccountInfo from './TopMenuAccountInfo';

const TopPanel = () => {
  return (
    <div className="menu-top justify-between relative">
      <div className="fixed top-0 w-full h-2 sidebar-draggable" />
      <div className="flex">
        <a
          href={window.electron?.isApp ? `#` : `/`}
          className="sidebar-draggable flex w-[70px] relative">
          <div className="absolute w-full h-full hover:bg-[#26131353] bg-transparent transition-all" />
          <span className="m-auto">
            <img
              className="w-[48px] h-[46px] rounded"
              src="/images/iuxtrade/IUXTrade-icon.png"
              height="auto"
            />
          </span>
        </a>
        <CustomScrollTopMenu />
      </div>
      <TopMenuAccountInfo />
    </div>
  );
};

export default TopPanel;
