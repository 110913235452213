export const ACTION_TYPES = {
  TRADE: 0,
  PENDING: 1,
  DEPOSIT: 2,
  CREDIT: 3
};

export const FILTER_PERIODS = {
  LAST_WEEK: 0,
  LAST_MONTH: 1,
  LAST_3_MONTHS: 2,
  LAST_6_MONTHS: 3,
  LAST_YEAR: 4,
  CUSTOM: 5,
  ALL_TIME: 6,
  TODAY: 7
};

export const LIST_FILTER_HISTORY_OPTION = [
  {
    name: 'All',
    key: 6
  },
  {
    name: 'Today',
    key: 7
  },
  {
    name: 'Last Week',
    key: 0
  },
  {
    name: 'Last Month',
    key: 1
  },
  {
    name: 'Last 3 Month',
    key: 2
  },
  {
    name: 'Last 6 Month',
    key: 3
  },
  {
    name: 'Last Year',
    key: 4
  },
  {
    name: 'Custom',
    key: 5
  }
];

export const ACTION_DEAL_TYPES = {
  TRADE: 0,
  PENDING: 1,
  DEPOSIT_WITHDRAWAL: 2,
  CREDIT: 3
};

export const DEAL_FIELDS = {
  DEAL: 0,
  EXTERNAL_ID: 1,
  LOGIN: 2,
  DEALER: 3,
  ORDER: 4,
  ACTION: 5,
  ENTRY: 6,
  DIGITS: 7,
  DIGITS_CURRENCY: 8,
  CONTRACT_SIZE: 9,
  TIME: 10,
  SYMBOL: 11,
  PRICE: 12,
  VOLUME: 13,
  PROFIT: 14,
  STORAGE: 15,
  COMMISSION: 16,
  RATE_MARGIN: 17,
  EXPERT_ID: 18,
  POSITION_ID: 19,
  COMMENT: 20,
  TIME_MSC: 21,
  PRICE_SL: 22,
  PRICE_TP: 23,
  FEE: 24,
  VALUE: 25,
  SWAP: 26,
};

export const ORDER_FIELDS = {
  ORDER: 0,
  EXTERNAL_ID: 1,
  LOGIN: 2,
  DEALER: 3,
  SYMBOL: 4,
  DIGITS: 5,
  DIGITS_CURRENCY: 6,
  CONTRACT_SIZE: 7,
  STATE: 8,
  REASON: 9,
  TIME_SETUP: 10,
  TIME_EXPIRATION: 11,
  TIME_DONE: 12,
  TYPE: 13,
  TYPE_FILL: 14,
  TYPE_TIME: 15,
  PRICE_ORDER: 16,
  PRICE_TRIGGER: 17,
  PRICE_CURRENT: 18,
  PRICE_SL: 19,
  PRICE_TP: 20,
  VOLUME_INITIAL: 21,
  VOLUME_CURRENT: 22,
  EXPERT_ID: 23,
  POSITION_ID: 24,
  COMMENT: 25,
  TIME_SETUP_MSC: 30,
  TIME_DONE_MSC: 31,
  RATE_MARGIN: 32
};

// Constants for order states
export const ORDER_STATES = {
  FAILED: 4,
  CANCELED: 2,
  EXPIRED: 6
};
