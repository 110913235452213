import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import OtpInput from '../../components/accounts/otp/OtpInput';
import { createOtpApi, verifyOtpApi } from '../../api/account';
import useAuth from '../../hooks/useAuth';
import GoToApp from '../../components/panel/GoToApp';
import { ORIGIN } from '../../constants';

export const Login = ({ isMobile, noAcc }) => {
  const { t, i18n } = useTranslation();
  const { login, authWithToken, logout, isOtp, isInitialized } = useAuth();
  let { token = '' } = useParams();
  const buttonRef = useRef();
  const [eye, setEye] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [countDownOTP, setCountDownOTP] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [targetTime, setTargetTime] = useState(null);
  const [isErrorOtp, setIsErrorOtp] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(true);

  const [otpData, setOtpData] = useState({
    reference: '',
    to: '',
    created: ''
  });
  const [payload, setPayload] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const onLogin = async () => {
    try {
      setIsErrorOtp(false);
      setButtonLoading(true);
      await login({
        username: payload.email,
        password: payload.password,
        setLoading: setButtonLoading
      });
    } catch (e) {
      console.log('e', e);
    }
  };

  const onChange = (value, key) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  useEffect(() => {
    if (token) {
      authWithToken({ token: token, setLoading: setLoading });
    }
  }, [token, setLoading, isInitialized]);

  useEffect(() => {
    if (!isInitialized && token) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [isInitialized, token]);

  const handleCreateOtp = async () => {
    try {
      setLoadingOtp(true);
      const { data } = await createOtpApi();
      setOtpData(data.data);
      setTargetTime(data.data?.created);
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    (async () => {
      if (isOtp) {
        await handleCreateOtp();
      }
      // await logout();
    })();
  }, [isOtp]);

  const countDownDuration = 3 * 60 * 1000; // 3 minutes in milliseconds

  useEffect(() => {
    const targetDate = new Date(targetTime);
    const endTime = new Date(targetDate?.getTime() + countDownDuration);
    const updateCountdown = () => {
      const now = new Date();
      const timeDiff = endTime?.getTime() - now?.getTime(); // Difference in milliseconds

      if (timeDiff <= 0) {
        clearInterval(myInterval);
        setMinutes(0);
        setCountDownOTP(0);
      } else {
        const remainingMinutes = Math.floor(timeDiff / (60 * 1000));
        const remainingSeconds = Math.floor((timeDiff % (60 * 1000)) / 1000);
        setMinutes(remainingMinutes);
        setCountDownOTP(remainingSeconds);
        setLoadingOtp(false);
      }
    };

    const myInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [targetTime]);

  const onChangeOtp = async (value) => {
    setOtp(value);
    if (value.replaceAll(' ', '').length === 6) {
      await verifyOtpApi({
        code: value,
        reference: otpData.reference
      })
        .then(({ data }) => {
          authWithToken({ token: data.data?.token, setLoading: setLoading });
          setIsErrorOtp(false);
        })
        .catch((e) => {
          console.log('e', e);
          setIsErrorOtp(true);
        });
    } else {
      setIsErrorOtp(false);
    }
  };
  const [isSuccess, setIsSuccess] = useState(false);

  if (!isMobile) {
    return (
      <div className="w-full">
        {loading && (
          <div
            className={`bg-[url(/public/img-landing2.svg)] z-10 w-full h-full bg-cover bg-center fixed left-0 top-0 transition-all`}>
            <div className="flex w-full h-full left-0 top-0 bg-[#00000075] z-10">
              <div className="m-auto">
                <div className="wrapper">
                  <div className="loading-text">
                    <h1 className="text-white text-2xl text-center">
                      LOADING
                      <span className="dot-one"> .</span>
                      <span className="dot-two"> .</span>
                      <span className="dot-three"> .</span>
                    </h1>
                    <div className="text-white text-center mt-2">
                      Please wait. Required data is being loaded.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!noAcc ? (
          <div className="w-full max-w-[450px] h-auto bg-[#2F2F2F] rounded-xl flex m-auto relative">
            {isOtp ? (
              <div className="bg-[#2F2F2F] p-10 rounded-2xl h-[440px] w-full">
                <div className="text-center">
                  <div className="flex">
                    <img
                      className="rounded-lg m-auto"
                      src="/images/iuxtrade/IUXTrade-icon.png"
                      width="88px"
                      height="auto"
                    />
                  </div>
                  <div className="text-2xl text-white text-center mt-10 font-extrabold">
                    {t('otp.title')}
                  </div>
                  <div className="mt-4 max-w-[360px] leading-[19.12px]">
                    <Trans
                      i18nKey="otp.verify.word"
                      values={{ email: otpData.to }}
                      components={{
                        bi: <bi className="text-sm text-[#868686]" />,
                        span: <span className="text-sm text-[#868686]" />
                      }}
                    />
                    <br />
                    <Trans
                      i18nKey="otp.verify.ref"
                      values={{ ref: otpData.reference }}
                      components={{
                        bi: <bi className="text-sm text-[#868686]" />,
                        span: <span className="text-sm text-[#868686]" />
                      }}
                    />
                  </div>
                  <div className="flex mt-10 justify-center">
                    <OtpInput
                      value={otp}
                      valueLength={6}
                      onChange={onChangeOtp}
                      isError={isErrorOtp}
                      isSuccess={isSuccess}
                    />
                  </div>
                  {loadingOtp ? (
                    <div className="mt-4 flex">
                      <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="40px"
                        height="40px"
                        viewBox="0 0 40 40"
                        enableBackground="new 0 0 40 40"
                        xmlSpace="preserve"
                        className="m-auto">
                        <path
                          opacity="0.2"
                          fill="#1CB894"
                          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                        />
                        <path
                          fill="#1CB894"
                          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                  C22.32,8.481,24.301,9.057,26.013,10.047z">
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 20 20"
                            to="360 20 20"
                            dur="0.9s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </div>
                  ) : (
                    <div className="mt-4">
                      {minutes <= 0 && countDownOTP <= 0 ? (
                        <div
                          className="font-weight-bold cursor-pointer flex items-center justify-center"
                          onClick={() => handleCreateOtp()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.50065 1.83334C2.86884 1.83334 3.16732 2.13182 3.16732 2.50001V4.33335L4.05604 3.53104C5.23505 2.47579 6.79362 1.83334 8.50065 1.83334C12.1825 1.83334 15.1673 4.81811 15.1673 8.50001C15.1673 12.1819 12.1825 15.1667 8.50065 15.1667C5.32709 15.1667 2.67289 12.9498 1.99919 9.98087C1.91771 9.62181 2.14274 9.26468 2.5018 9.1832C2.86087 9.10173 3.21799 9.32676 3.29947 9.68582C3.83838 12.0608 5.96332 13.8333 8.50065 13.8333C11.4462 13.8333 13.834 11.4455 13.834 8.50001C13.834 5.55449 11.4462 3.16668 8.50065 3.16668C7.13476 3.16668 5.89018 3.67927 4.9463 4.52362L4.23399 5.16668H5.83398C6.20217 5.16668 6.50065 5.46515 6.50065 5.83334C6.50065 6.20153 6.20217 6.50001 5.83398 6.50001H2.50065C2.13246 6.50001 1.83398 6.20153 1.83398 5.83334V2.50001C1.83398 2.13182 2.13246 1.83334 2.50065 1.83334Z"
                              fill="#1CB894"
                            />
                          </svg>
                          <span className="text-[#1CB894]">&nbsp;Resend Code</span>
                        </div>
                      ) : (
                        <>
                          <Trans
                            i18nKey="otp.verify.count"
                            values={{
                              count: `${minutes < 10 ? `0${minutes}` : minutes}:
                              ${countDownOTP < 10 ? `0${countDownOTP}` : countDownOTP}`
                            }}
                            components={{
                              bi: <bi className="font-extrabold text-[#1CB894] text-sm" />,
                              span: <span className="text-sm text-[#868686]" />
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="px-10 py-10">
                <div className="flex flex-col items-center mb-4">
                  <div className="w-[88px] h-[88px] mb-4">
                    <a
                      href="#"
                      onClick={(_) => {
                        // const auth = {
                        //   username: 'worawuth',
                        //   password: 'AAbb12345'
                        // };
                        // window.electron?.writeFile.setFile(JSON.stringify(auth));
                        // window.electron.store.set('foo', 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFlYzU4NjcwNGNhOTZiZDcwMzZiMmYwZDI4MGY5NDlmM2E5NzZkMzgiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQWxleCBUdW5uZXIiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaXV4bWFya2V0cyIsImF1ZCI6Iml1eG1hcmtldHMiLCJhdXRoX3RpbWUiOjE3MDg0MDcxNTMsInVzZXJfaWQiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJzdWIiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJpYXQiOjE3MDg0MDcxNTMsImV4cCI6MTcwODQxMDc1MywiZW1haWwiOiJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.QBI0qb6Oq0YML8ILKCK_jHFylwQp5MTxkXbP4rbybZ2S0cfGdBl1hgUrlvVS2Ph5hDRXPyCwBOLOTz-UNyK98LoOv2v4xuaRACzqb-I0GfgaXQ0ipbR8yLxgWt_tzNWKXjBiBaLOqTVOJq_yxM5xcTemm3b9G8WNT5aUru17OPTNAPZkENnUNH3bfb-eCzx28cEGWCQm9KkJokWYNUeT-2mzSQL20NEi5rwXbYUasdrUnJeBwOGMLlI5BmFZKLGrx-dImRnxwlU8-mGz5St1_ll5jGeIS4qIixiPDqQKsQbPXa6zHzFj-3iJ8salnEODUJqLp_Ax4-j2PmNNvoxtGQ');
                        // console.log(window.electron.store.get('foo'));
                      }}>
                      <img
                        className="rounded-lg"
                        src="/images/iuxtrade/IUXTrade-icon.png"
                        width="88px"
                        height="auto"
                      />
                    </a>
                  </div>
                  <div className="font-bold text-center text-white">{t('login.title')}</div>
                </div>
                <div className="flex justify-between mb-4">
                  <div className="text-white text-xs">{t('login.subtitle')}</div>
                  <div className="text-[#169376] font-bold text-xs">
                    <Link to={`${ORIGIN[window.location.origin]}/ja/register`}>
                      {t('login.register')}
                    </Link>
                  </div>
                </div>
                <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.email')}</label>
                <input
                  type="text"
                  placeholder={t('login.email')}
                  className="rounded-lg h-[40px] w-full px-2 mb-4 text-sm outline-none bg-transparent border border-[#696969] text-white"
                  onChange={({ target: { value } }) => onChange(value, Object.keys(payload)[0])}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter') {
                      buttonRef.current.click();
                    }
                  }}
                />
                <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.password')}</label>
                <div className="relative">
                  <input
                    type={eye ? 'text' : 'password'}
                    placeholder={t('login.password')}
                    className="rounded-lg h-[40px] w-full px-2 mb-5 text-sm outline-none bg-transparent border border-[#696969] text-white"
                    onChange={({ target: { value } }) => onChange(value, Object.keys(payload)[1])}
                    onKeyPress={(e) => {
                      if (e.code === 'Enter') {
                        buttonRef.current.click();
                      }
                    }}
                  />
                  {!eye ? (
                    <svg
                      onClick={() => setEye(!eye)}
                      width="20"
                      height="20"
                      className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        {' '}
                        <path
                          d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M20.9994 3L17.6094 6.39"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M6.38 17.62L3 21"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                      </g>
                    </svg>
                  ) : (
                    <svg
                      onClick={() => setEye(!eye)}
                      className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        {' '}
                        <path
                          d="M12 16.01C14.2091 16.01 16 14.2191 16 12.01C16 9.80087 14.2091 8.01001 12 8.01001C9.79086 8.01001 8 9.80087 8 12.01C8 14.2191 9.79086 16.01 12 16.01Z"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M2 11.98C8.09 1.31996 15.91 1.32996 22 11.98"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                        <path
                          d="M22 12.01C15.91 22.67 8.09 22.66 2 12.01"
                          stroke="#696969"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>{' '}
                      </g>
                    </svg>
                  )}
                </div>
                <div className="flex justify-between mb-8">
                  <div></div>
                  <div className="text-[#169376] font-bold text-xs">
                    <Link to={`${ORIGIN[window.location.origin]}/ja/forgot-password-form`}>
                      {t('login.forgotPassword')}
                    </Link>
                  </div>
                </div>
                <button
                  ref={buttonRef}
                  className="h-[35px] rounded-md w-full bg-[#1cb894] text-white mb-5 disabled:bg-[#444444]"
                  onClick={() => onLogin()}
                  disabled={buttonLoading}>
                  {t('login.login')}
                </button>
                <p className="text-[#8d8d8d] text-center leading-4">{t('login.desc')}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="m-auto min-h-[400px] flex flex-col">
            <div className="m-auto">
              <img className="m-auto" src="/images/none-account.svg" />
              <p className="text-center my-4">You don’t have any accounts</p>
              <div className="text-center">
                <a href={`#`}>
                  <button className="transition-all bg-[#1cb894] hover:bg-[#098a6c] rounded-lg border-none text-white px-3 py-2 font-[600] uppercase text-sm">
                    Go to Dashbaord
                  </button>
                </a>
                <div className="mt-4">
                  <a
                    href="#"
                    onClick={() => logout()}
                    className="text-[#1cb894] text-sm hover:underline cursor-pointer">
                    Login another account
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <GoToApp />;
  }
};
