import { setCategories, setInstrument, setMenuSymbol } from '../../redux/reducer/instruments';
import EventEmitter from '../../utilities/service.js';

const extractCategories = (events) => {
  return events.reduce(
    (acc, event) => ({
      ...acc,
      [event.type]: event.type
    }),
    {}
  );
};

const findDefaultSymbol = (events) => {
  return events.find((event) => event.symbol.includes('XAUUSD'));
};

const createInitialMenuState = (accountId, symbolData) => {
  return {
    [accountId]: {
      menu: [
        {
          symbol: symbolData.symbol,
          logo: symbolData.logo
        }
      ],
      active: symbolData.symbol
    },
    accountId
  };
};

const handleExistingMenu = (prevMenu, accountId, firstSymbolData) => {
  const prevJson = JSON.parse(prevMenu);

  if (!prevJson[accountId]) {
    // Create new account entry while preserving existing data
    return {
      ...prevJson,
      [accountId]: {
        menu: [
          {
            symbol: firstSymbolData.symbol,
            logo: firstSymbolData.logo
          }
        ],
        active: firstSymbolData.symbol
      },
      accountId
    };
  }

  // Return existing account data
  return {
    ...prevJson,
    accountId
  };
};

const initializeMenuSymbol = async ({
  screen,
  isAuthenticated,
  activeAccount,
  dispatch,
  setLoading
}) => {
  if (!screen && isAuthenticated) {
    try {
      await EventEmitter.subscribe('listArray', (events) => {
        if (!events?.length) {
          throw new Error('No events received');
        }

        // Extract categories and find default symbol
        const categories = extractCategories(events);
        const firstSymbolData = findDefaultSymbol(events);

        if (!firstSymbolData) {
          throw new Error('Default symbol XAUUSD not found');
        }

        // Update instruments and categories
        dispatch(setInstrument(events));
        dispatch(setCategories(categories));

        if (!activeAccount) {
          return;
        }

        // Handle menu storage logic
        const prevMenu = localStorage.getItem('symbol_menu_list');
        let menuState;

        if (prevMenu) {
          menuState = handleExistingMenu(prevMenu, activeAccount, firstSymbolData);
          dispatch(setMenuSymbol({ type: 'old', data: menuState }));

          if (!menuState[activeAccount]) {
            localStorage.setItem('symbol_menu_list', JSON.stringify(menuState));
          }
        } else {
          menuState = createInitialMenuState(activeAccount, firstSymbolData);
          dispatch(setMenuSymbol({ type: 'first', data: menuState }));
        }
      });
    } catch (error) {
      console.error('Error initializing menu symbol:', error);
      // Handle error appropriately - could dispatch error action or show notification
    } finally {
      setLoading(false);
    }
  }
};

export default initializeMenuSymbol;
