import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-[#272727] px-4">
      <div className="container mx-auto">
        <div className="bg-[#272727]">
          <div className="px-4 lg:px-8 pb-8 container mx-auto">
            <div>
              <div className="h-10" />
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img
                    className="h-[82px]"
                    src="/images/iuxtrade/iuxtrade-logo.png"
                    alt="iuxtrade.com"
                  />
                </div>
                <div />
              </div>
            </div>
            <p className="text-[#aaaaaa] text-xs mt-[38px] mb-4">{t('desc_1')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_2')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_3')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">{t('desc_4')}</p>
            <p className="text-[#aaaaaa] text-xs mb-4">
              <Trans
                t={t}
                i18nKey="desc_5"
                components={{
                  bi: (
                    <a
                      href="https://iuxtrade.com"
                      className="text-[#1CB894] hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
