import moment from 'moment';
import { ACTION_TYPES } from '../constant';
import { createHistoryObject } from '../../../../helpers/position';

export const sumProfits = (transactions, condition) => {
  return transactions?.reduce(
    (sum, transaction) => (condition(transaction) ? sum + transaction.profit : sum),
    0
  );
};

export const sumCommission = (transactions, condition) => {
  return transactions?.reduce(
    (sum, transaction) => (condition(transaction) ? sum + transaction.commission : sum),
    0
  );
};

// Helper function to filter transactions by action and profit condition
export const filterAndSumProfits = (transactions, action, profitCondition = () => true) => {
  return sumProfits(
    transactions?.filter((t) => t.action === action),
    profitCondition
  );
};

// Main processing function
export const processTradeHistory = (histories, setInfo, setHistory, setHistoryFilter) => {
  if (histories?.length <= 0 || histories === undefined) {
    return;
  }

  const history = histories.map(createHistoryObject);

  // Filter transactions for today
  const today = moment();
  const todayTransactions = history?.filter((transaction) =>
    moment(transaction.time_format).isSame(today, 'day')
  );

  // Calculate financial metrics
  const financialMetrics = {
    credit: filterAndSumProfits(todayTransactions, ACTION_TYPES.CREDIT, (t) => t.profit > 0),
    deposit: filterAndSumProfits(todayTransactions, ACTION_TYPES.DEPOSIT, (t) => t.profit > 0),
    withdrawal: filterAndSumProfits(todayTransactions, ACTION_TYPES.DEPOSIT, (t) => t.profit < 0),
    profit: sumProfits(
      todayTransactions?.filter(
        (t) => t.action === ACTION_TYPES.TRADE || t.action === ACTION_TYPES.PENDING
      ),
      () => true
    ),
    balance:
      sumProfits(todayTransactions, () => true) + sumCommission(todayTransactions, () => true),
    commission: sumCommission(todayTransactions, () => true)
  };

  // Update state
  setInfo(financialMetrics);
  setHistory(history);
  setHistoryFilter({
    index: 7,
    data: [...todayTransactions].reverse()
  });

  return financialMetrics;
};

export default processTradeHistory;
