import React, { memo, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app';
import { setPayload } from '../../../redux/reducer/payload';
import { makeBidAskSelector } from '../../../redux/selector';
import UseStopLossTakeProfit from '../../../utilities/useStopLossTakeProfit';

const StopLoss = memo(({ handlePrice }) => {
  const payload = useAppSelector((state) => state.payload?.payload);
  const dispatch = useAppDispatch();
  const getBidAsk = useMemo(makeBidAskSelector, []);
  const bid = useAppSelector((state) => getBidAsk(state, 'bid'));

  const instrumentInfo = useAppSelector((state) => state.symbol.instrumentInfo);

  const digit = instrumentInfo?.digit ?? 0;

  return (
    <div className="box-input-lotsize">
      <div>
        <button
          className="dec"
          onClick={() => {
            handlePrice('dec', payload.price_sl, 'price_sl', digit, dispatch, payload, bid);
          }}>
          -
        </button>
      </div>
      <input
        placeholder="0000.00"
        type="number"
        onChange={({ target: { value } }) => {
          dispatch(
            setPayload({
              ...payload,
              price_sl: +value
            })
          );
        }}
        value={payload.price_sl <= 0 ? '' : payload.price_sl}
        className="input-left-lotsize m-[10px]"
        onMouseDown={() => {
          if (payload.price_sl === 0 || payload.price_sl === '') {
            dispatch(
              setPayload({
                ...payload,
                price_sl: bid
              })
            );
          }
        }}
      />
      <div>
        <button
          className="inc"
          onClick={() => {
            handlePrice('inc', payload.price_sl, 'price_sl', digit, dispatch, payload, bid);
          }}>
          +
        </button>
      </div>
    </div>
  );
});

StopLoss.displayName = 'StopLoss';

const TakeProfit = memo(({ handlePrice }) => {
  const payload = useAppSelector((state) => state.payload?.payload);
  const dispatch = useAppDispatch();
  const getBidAsk = useMemo(makeBidAskSelector, []);
  const bid = useAppSelector((state) => getBidAsk(state, 'bid'));

  const instrumentInfo = useAppSelector((state) => state.symbol.instrumentInfo);

  const digit = instrumentInfo?.digit ?? 0;

  return (
    <div className="box-input-lotsize">
      <div>
        <button
          className="dec"
          onClick={() => {
            handlePrice('dec', payload.price_tp, 'price_tp', digit, dispatch, payload, bid);
          }}>
          -
        </button>
      </div>
      <input
        placeholder="0000.00"
        type="number"
        onChange={({ target: { value } }) => {
          dispatch(
            setPayload({
              ...payload,
              price_tp: +value
            })
          );
        }}
        value={payload.price_tp <= 0 ? '' : payload.price_tp}
        className="input-left-lotsize m-[10px]"
        onMouseDown={() => {
          if (payload.price_tp === 0 || payload.price_tp === '') {
            dispatch(
              setPayload({
                ...payload,
                price_tp: bid ?? 0
              })
            );
          }
        }}
      />
      <div>
        <button
          className="inc"
          onClick={() => {
            handlePrice('inc', payload.price_tp, 'price_tp', digit, dispatch, payload, bid);
          }}>
          +
        </button>
      </div>
    </div>
  );
});

TakeProfit.displayName = 'TakeProfit';

const StopLossAndTakeProfit = memo(() => {
  const { handlePrice } = UseStopLossTakeProfit();

  return (
    <div className="flex gap-4">
      <div className="mt-4">
        <p className="mb-2 text-sm">Stop Loss</p>
        <StopLoss handlePrice={handlePrice} />
      </div>
      <div className="mt-4">
        <p className="mb-2 text-sm">Take Profit</p>
        <TakeProfit handlePrice={handlePrice} />
      </div>
    </div>
  );
});

StopLossAndTakeProfit.displayName = 'StopLossAndTakeProfit';

export default StopLossAndTakeProfit;
