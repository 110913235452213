import { useEffect, useMemo, useState } from 'react';
import { makePositionNoReduceSelector } from '../../redux/selector';
import { useAppSelector } from '../../redux/app';
import { addWorkerMessageListener, initializeWorker, postMessageToWorker } from '../serviceWorker';

const POSITION_MESSAGE_TYPE = 'position';

const WorkerController = () => {
  const getPositionState = useMemo(makePositionNoReduceSelector, []);
  const positions = useAppSelector((state) => getPositionState(state));
  const [workerData, setWorkerData] = useState([]);

  useEffect(() => {
    initializeWorker();

    const handleWorkerMessage = (event) => {
      const { type, data } = event.data;
      if (type === POSITION_MESSAGE_TYPE) {
        setWorkerData(data);
      }
    };

    addWorkerMessageListener(handleWorkerMessage);

    postMessageToWorker({
      type: POSITION_MESSAGE_TYPE,
      payload: positions
    });

    // Cleanup not needed since worker is managed externally
  }, [positions]);

  return { data: workerData };
};

export default WorkerController;
