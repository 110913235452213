import EventEmitter from 'events';

class Emitter {
  constructor() {
    this.instance = new EventEmitter();
    this.instance.setMaxListeners(20);
  }
}

export default new Emitter();
