import React, { memo, useEffect, useState } from 'react';
import { setPayload } from '../../../redux/reducer/payload';
import { useAppDispatch, useAppSelector } from '../../../redux/app';

const Expiration = memo(() => {
  const payload = useAppSelector((state) => state.payload?.payload);
  const dispatch = useAppDispatch();

  const [select, setSelect] = useState(0);

  useEffect(() => {
    if (select < 2) {
      dispatch(setPayload({ ...payload, type_time: select, time_expiration: 0 }));
    } else {
      dispatch(setPayload({ ...payload, type_time: select }));
    }
  }, [select]);

  return (
    <div className="select-expire">
      <select
        className=""
        defaultValue={select}
        onChange={({ target: { value } }) => {
          setSelect(+value);
        }}>
        <option value={0}>GTC</option>
        <option value={1}>Today</option>
        <option value={2}>Specified</option>
        <option value={3}>Specified Day</option>
      </select>
    </div>
  );
});
Expiration.displayName = 'Expiration';

export default Expiration;
