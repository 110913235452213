// Helper function to calculate financial metrics
import { ACTION_TYPES } from '../constant'

export const calculateFinancialMetrics = (transactions) => {
  const credit = transactions.filter((t) => t.action === ACTION_TYPES.CREDIT).reduce((sum, t) => sum + t.profit, 0)
  const deposit = transactions.filter((t) => t.action === ACTION_TYPES.DEPOSIT && t.profit > 0).reduce((sum, t) => sum + t.profit, 0)
  const withdrawal = transactions.filter((t) => t.action === ACTION_TYPES.DEPOSIT && t.profit < 0).reduce((sum, t) => sum + t.profit, 0)
  const profit = transactions.filter((t) => t.action === ACTION_TYPES.TRADE || t.action === ACTION_TYPES.PENDING).reduce((sum, t) => sum + t.profit, 0)
  const balance = transactions.reduce((sum, t) => sum + t.profit + t.commission, 0)
  const commission = transactions.reduce((sum, t) => sum + t.commission, 0)

  return {
    credit,
    deposit,
    withdrawal,
    profit,
    balance,
    commission,
  }
}