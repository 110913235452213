import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTradeDialog } from '../../../redux/reducer/dialog';
import useAuth from '../../../hooks/useAuth';
import { useAppSelector } from '../../../redux/app';
import { Navigate } from 'react-router-dom';

const LeftPanel = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState('');
  const { logout } = useAuth();
  const { isOpen, type } = useAppSelector((state) => state.tradeDialog);

  const handleOpenDrawer = (type, action) => {
    if (isOpen) {
      if (type === active) {
        dispatch(setTradeDialog({ isOpen: false, type: '' }));
      } else {
        dispatch(setTradeDialog({ isOpen: action, type }));
        setActive(type);
      }
    } else {
      dispatch(setTradeDialog({ isOpen: action, type }));
      setActive(type);
    }
  };

  const handleLogout = async () => {
    await logout();
    // <Navigate to="login" />
  };

  return (
    <div
      style={{
        transition: 'all 0.3s ease 0s'
      }}
    >
      <div className="menu-left">
        <div className="center-column">
          <div
            className={`menu-item ${active === 'trade' ? (type !== '' ? 'active' : '') : ''}`}
            onClick={() => {
              handleOpenDrawer('trade', true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2221_220)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.137342 8.60837C0.0748822 8.73329 0.090497 8.85821 0.168571 8.96751L3.69752 13.4958C3.75287 13.565 3.83275 13.6097 3.92628 13.6189C3.83366 13.6092 3.75457 13.5647 3.69964 13.496L0.170692 8.96774C0.0926176 8.85843 0.0770028 8.73351 0.139462 8.60859C0.201921 8.49929 0.32684 8.42122 0.451759 8.42122H1.68533V2.7218C1.68533 2.53443 1.84148 2.37828 2.02886 2.37828H5.93256C5.94407 2.37828 5.95545 2.37887 5.96669 2.38001C5.95477 2.37872 5.94267 2.37805 5.93044 2.37805H2.02674C1.83936 2.37805 1.68321 2.5342 1.68321 2.72158V8.42099H0.449638C0.32472 8.42099 0.199801 8.49907 0.137342 8.60837ZM6.27609 8.40538V8.4056H7.48718H7.49405C7.50411 8.4056 7.51408 8.40611 7.52392 8.40711C7.51339 8.40596 7.50272 8.40538 7.49193 8.40538H6.27609ZM7.82213 9.02491C7.91707 8.89199 7.94275 8.71348 7.86265 8.56637C7.80078 8.40975 7.65737 8.30662 7.49102 8.30662H6.37273V2.72158C6.37273 2.47966 6.17236 2.2793 5.93044 2.2793H2.02674C1.78482 2.2793 1.58445 2.47966 1.58445 2.72158V8.32224H0.449638C0.286312 8.32224 0.129983 8.42189 0.0514636 8.5593L0.0490099 8.56421C-0.0310391 8.7243 -0.00954973 8.8882 0.0881386 9.02496L3.61962 13.5565L3.62041 13.5575C3.70178 13.6592 3.82395 13.7195 3.96298 13.7195C4.102 13.7195 4.22418 13.6592 4.30555 13.5575L7.81985 9.0281L7.82213 9.02491ZM3.97956 12.7294L3.98071 12.7309L6.79138 9.07704H6.78909L3.97956 12.7294ZM5.88296 9.07516C5.81239 9.06796 5.73476 9.03728 5.68061 8.98313C5.61815 8.92067 5.58692 8.84259 5.58692 8.74891V3.06533H5.58904V8.74913C5.58904 8.84282 5.62027 8.92089 5.68273 8.98335C5.73634 9.03696 5.81295 9.06756 5.88296 9.07516ZM6.5887 9.17557L3.97821 12.5692L1.35408 9.19119H2.02674C2.26866 9.19119 2.46902 8.99083 2.46902 8.74891V3.16386H5.48816V8.74891C5.48816 8.86702 5.52858 8.97077 5.61077 9.05296C5.69455 9.13673 5.8148 9.17557 5.91483 9.17557H6.5887Z"
                  // fill="#8e9199"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9472 7.43458C16.0273 7.27448 16.0057 7.11064 15.9081 6.97387L12.3446 2.44128C12.2632 2.33956 12.1411 2.2793 12.0021 2.2793C11.863 2.2793 11.7409 2.33955 11.6595 2.44127L8.14518 6.97069L8.1429 6.97387C8.04645 7.10891 8.02149 7.291 8.10629 7.43941C8.18352 7.57457 8.32304 7.67655 8.50433 7.67655H9.62353V13.2772C9.62353 13.5191 9.8239 13.7195 10.0658 13.7195H13.9695C14.2114 13.7195 14.4118 13.5191 14.4118 13.2772V7.67655H15.5466C15.7099 7.67655 15.8663 7.5769 15.9448 7.43949L15.9472 7.43458ZM12.0416 2.38014C12.0293 2.37891 12.0169 2.37828 12.0042 2.37828C11.8949 2.37828 11.8012 2.42512 11.7387 2.5032L8.22538 7.0315C8.14731 7.1408 8.13169 7.28133 8.19415 7.39064C8.25058 7.48939 8.34525 7.56266 8.46665 7.57588C8.34424 7.56328 8.2488 7.48974 8.19204 7.39041C8.12958 7.28111 8.14519 7.14058 8.22327 7.03127L11.7366 2.50297C11.7991 2.4249 11.8928 2.37805 12.0021 2.37805C12.0155 2.37805 12.0287 2.37876 12.0416 2.38014ZM9.72229 7.57802V13.2772C9.72229 13.4531 9.85988 13.6015 10.0317 13.619C9.86087 13.6005 9.7244 13.4526 9.7244 13.2774V7.57802H9.72229ZM13.9695 6.8076C13.7276 6.8076 13.5272 7.00796 13.5272 7.24988V12.8349H10.5081V7.24988C10.5081 7.13176 10.4677 7.02802 10.3855 6.94583C10.3017 6.86205 10.1815 6.82321 10.0814 6.82321H9.40809L12.0178 3.44504L14.6267 6.8076H13.9695ZM9.20929 6.92197L9.20911 6.92219H10.0835C10.094 6.92219 10.1047 6.92275 10.1156 6.92387C10.104 6.9226 10.0926 6.92197 10.0814 6.92197H9.20929ZM14.8283 6.90635L12.0188 3.28519L12.0198 3.28394L14.8305 6.90658H13.9716C13.7843 6.90658 13.6281 7.06273 13.6281 7.2501V12.9339H10.4115V12.9337H13.626V7.24988C13.626 7.0625 13.7821 6.90635 13.9695 6.90635H14.8283Z"
                  // fill="#8e9199"
                />
              </g>
              <defs>
                <clipPath id="clip0_2221_220">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="text-menu prevent-select">Trading</div>
          </div>
          <div
            className={`menu-item ${active === 'port' ? (type !== '' ? 'active' : '') : ''}`}
            onClick={() => {
              handleOpenDrawer('port', true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2219_189)">
                <path d="M7.42937 0C7.2782 0 7.13322 0.0600534 7.02632 0.166949C6.91943 0.273845 6.85938 0.418827 6.85938 0.57V7.43C6.85938 7.88485 7.04006 8.32106 7.36169 8.64269C7.68331 8.96431 8.11953 9.145 8.57437 9.145H15.4294C15.5047 9.145 15.5792 9.1301 15.6487 9.10114C15.7182 9.07218 15.7812 9.02975 15.8342 8.97629C15.8872 8.92283 15.9291 8.8594 15.9574 8.78967C15.9858 8.71993 16 8.64527 15.9994 8.57C15.9967 6.29791 15.093 4.11963 13.4864 2.51302C11.8797 0.906404 9.70147 0.00264659 7.42937 0ZM8.57437 8C8.4991 8.00066 8.42444 7.9864 8.35471 7.95806C8.28498 7.92971 8.22155 7.88782 8.16809 7.83483C8.11463 7.78183 8.07219 7.71877 8.04324 7.64929C8.01428 7.57981 7.99937 7.50528 7.99937 7.43V1.165C9.76596 1.30366 11.4251 2.0683 12.6781 3.32131C13.9311 4.57432 14.6957 6.23341 14.8344 8H8.57437Z" />
                <path d="M14.0798 10.3196C14.0089 10.2943 13.9337 10.2833 13.8586 10.2872C13.7834 10.2911 13.7097 10.3099 13.6419 10.3425C13.574 10.375 13.5133 10.4206 13.4631 10.4768C13.413 10.5329 13.3745 10.5985 13.3498 10.6696C12.9037 11.9558 12.0488 13.0607 10.9157 13.8155C9.78265 14.5703 8.43367 14.9335 7.07481 14.8496C5.5217 14.7532 4.0585 14.0881 2.96465 12.9814C1.8708 11.8746 1.22294 10.4037 1.14481 8.84956C1.08042 7.503 1.45315 6.17172 2.20737 5.05435C2.96159 3.93699 4.05688 3.09343 5.32981 2.64956C5.40072 2.62461 5.46603 2.58593 5.52199 2.53575C5.57796 2.48556 5.6235 2.42483 5.656 2.35705C5.6885 2.28926 5.70733 2.21574 5.71141 2.14067C5.7155 2.06561 5.70476 1.99047 5.67981 1.91956C5.65486 1.84865 5.61618 1.78334 5.56599 1.72737C5.5158 1.67141 5.45508 1.62587 5.3873 1.59337C5.31951 1.56087 5.24598 1.54204 5.17092 1.53796C5.09586 1.53387 5.02072 1.54461 4.94981 1.56956C3.4475 2.09241 2.15416 3.08635 1.26226 4.40348C0.370361 5.7206 -0.0724205 7.29051 -0.000192318 8.87956C0.0891474 10.7187 0.851914 12.4607 2.1428 13.7737C3.43368 15.0867 5.16246 15.879 6.99981 15.9996H7.41981C8.95728 16.0084 10.459 15.5358 11.7143 14.6481C12.9696 13.7604 13.9157 12.5021 14.4198 11.0496C14.4714 10.9077 14.4645 10.7511 14.4008 10.6142C14.337 10.4774 14.2216 10.3714 14.0798 10.3196Z" />
              </g>
              <defs>
                <clipPath id="clip0_2219_189">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="text-menu prevent-select">All Port</div>
          </div>
          <div
            className={`menu-item ${active === 'history' ? (type !== '' ? 'active' : '') : ''}`}
            onClick={() => {
              handleOpenDrawer('history', true);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2255_588)">
                <g clipPath="url(#clip1_2255_588)">
                  <path
                    d="M7.9275 8.6525L10.9275 10.9025C11.1485 11.0683 11.4617 11.0235 11.6275 10.8025C11.7932 10.5815 11.7485 10.2682 11.5275 10.1025L8.75 8V3.75C8.75 3.47375 8.52625 3.25 8.25 3.25C7.97375 3.25 7.75 3.47375 7.75 3.75V8.25C7.75 8.416 7.8195 8.5655 7.9275 8.6525Z"
                    // fill="white"
                  />
                  <path
                    d="M8.50074 0.5C4.84099 0.5 1.71574 3.1415 1.10574 6.75L0.915737 6.4675C0.760987 6.23825 0.449987 6.17775 0.220737 6.3325C-0.00851262 6.48725 -0.0690126 6.79825 0.0857374 7.0275L1.08574 8.5275C1.16799 8.6525 1.30199 8.73425 1.45074 8.75H1.50074C1.63324 8.7495 1.75999 8.6965 1.85324 8.6025L3.10324 7.3525C3.29849 7.15725 3.29849 6.84025 3.10324 6.645C2.90799 6.44975 2.59099 6.44975 2.39574 6.645L2.08574 6.9575C2.66149 3.414 6.00074 1.0085 9.54399 1.58425C13.0872 2.16 15.4932 5.499 14.9175 9.0425C14.4062 12.189 11.6885 14.5005 8.50074 14.5C6.49024 14.5365 4.58874 13.588 3.40824 11.96C3.24799 11.735 2.93574 11.6823 2.71074 11.8425C2.48574 12.0027 2.43299 12.315 2.59324 12.54C3.96024 14.433 6.16624 15.5382 8.50074 15.5C12.643 15.5 16.0007 12.1423 16.0007 8C16.0007 3.85775 12.643 0.5 8.50074 0.5Z"
                    // fill="white"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_2255_588">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_2255_588">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="text-menu prevent-select">History</div>
          </div>
        </div>
        <div className="sidebar-draggable h-[calc(100vh_-_319px)]"></div>
        <div className="absolute left-0 bottom-0 translate-x-[25px] -translate-y-[15px] z-10">
          <button type="button" className="button-outline-logout" onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="#8e9199">
              {' '}
              <g>
                {' '}
                <path fill="none" d="M0 0h24v24H0z" />{' '}
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />{' '}
              </g>{' '}
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
