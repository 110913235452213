export const STORAGE_KEYS = {
  TOKEN: 'trade.token',
  SERVER: 'trade.server',
  ACTIVE_ACCOUNT: 'iuxmarkets.active.account'
};

export const type = {
  str: {
    buy: 'Buy',
    sell: 'Sell',
    editBuyLimit: 'Buy Limit',
    editSellLimit: 'Sell Limit',
    editBuyStop: 'Buy Stop',
    editSellStop: 'Sell Stop',
    editBuyStopLimit: 'Buy Stop Limit',
    editSellStopLimit: 'Sell Stop Limit'
  }
};

export const FILTER_LIST = [
  {
    name: 'All',
    key: 6
  },
  {
    name: 'Today',
    key: 7
  },
  {
    name: 'Last Week',
    key: 0
  },
  {
    name: 'Last Month',
    key: 1
  },
  {
    name: 'Last 3 Month',
    key: 2
  },
  {
    name: 'Last 6 Month',
    key: 3
  },
  {
    name: 'Last Year',
    key: 4
  },
  {
    name: 'Custom',
    key: 5
  }
];

export const ORIGIN = {
  'http://localhost:3000': 'https://portal-staging.iuxtrade.com',
  'https://trading-staging.iuxtrade.com': 'https://portal-staging.iuxtrade.com',
  'https://trading-beta.iuxtrade.com': 'https://portal-beta.iuxtrade.com',
  'https://trading.iuxtrade.com': 'https://portal.iuxtrade.com'
};

export const TYPE_SERVER = { api: 'Live' };
