import { ACTION_DEAL_TYPES, DEAL_FIELDS } from '../constant'
import moment from 'moment'

// Map raw deals data to structured history objects
export const mapDealsToHistory = (deals) => {
  return deals.map((item) => ({
    deal: item[DEAL_FIELDS.DEAL],
    external_id: item[DEAL_FIELDS.EXTERNAL_ID],
    login: item[DEAL_FIELDS.LOGIN],
    dealer: item[DEAL_FIELDS.DEALER],
    order: item[DEAL_FIELDS.ORDER],
    action: item[DEAL_FIELDS.ACTION] ?? 0,
    entry: item[DEAL_FIELDS.ENTRY],
    digits: item[DEAL_FIELDS.DIGITS],
    digits_currency: item[DEAL_FIELDS.DIGITS_CURRENCY],
    contract_size: item[DEAL_FIELDS.CONTRACT_SIZE],
    time: item[DEAL_FIELDS.TIME],
    timestamp: item[DEAL_FIELDS.TIME],
    symbol: item[DEAL_FIELDS.SYMBOL],
    price: item[DEAL_FIELDS.PRICE],
    volume: item[DEAL_FIELDS.VOLUME] ?? 0,
    profit: item[DEAL_FIELDS.PROFIT] ?? 0,
    storage: item[DEAL_FIELDS.STORAGE],
    commission: item[DEAL_FIELDS.COMMISSION] ?? 0,
    rate_margin: item[DEAL_FIELDS.RATE_MARGIN],
    expert_id: item[DEAL_FIELDS.EXPERT_ID],
    position_id: item[DEAL_FIELDS.POSITION_ID],
    comment: item[DEAL_FIELDS.COMMENT],
    time_msc: item[DEAL_FIELDS.TIME_MSC],
    price_sl: item[DEAL_FIELDS.PRICE_SL],
    price_tp: item[DEAL_FIELDS.PRICE_TP],
    fee: item[DEAL_FIELDS.FEE],
    value: item[DEAL_FIELDS.VALUE],
    swap: item[DEAL_FIELDS.SWAP],
    time_format: moment.unix(item[DEAL_FIELDS.TIME]).format('YYYY-MM-DD'),
  }))
}

// Filter transactions for today
export const filterTodayTransactions = (history) => {
  const today = moment().format('YYYY-MM-DD')
  return history?.filter((entry) => moment(entry.time_format).isSame(today))
}

// Calculate financial summary from transactions
export const calculateFinancialSummary = (transactions) => {
  const sumProfitByCondition = (condition) =>
    transactions.filter(condition).reduce((sum, transaction) => sum + transaction.profit, 0)

  return {
    credit: sumProfitByCondition((t) => t.action === ACTION_DEAL_TYPES.CREDIT),
    deposit: sumProfitByCondition(
      (t) => t.action === ACTION_DEAL_TYPES.DEPOSIT_WITHDRAWAL && t.profit >= 0,
    ),
    withdrawal: sumProfitByCondition(
      (t) => t.action === ACTION_DEAL_TYPES.DEPOSIT_WITHDRAWAL && t.profit < 0,
    ),
    profit: sumProfitByCondition(
      (t) => t.action === ACTION_DEAL_TYPES.TRADE || t.action === ACTION_DEAL_TYPES.PENDING,
    ),
    balance: transactions.reduce((sum, t) => sum + t.profit + t.commission, 0),
    commission: transactions.reduce((sum, t) => sum + t.commission, 0),
  }
}

const processDealHistory = (deals, setInfo, setHistory, setHistoryFilter) => {
  if (!deals?.length) return

  const history = mapDealsToHistory(deals)
  const todayTransactions = filterTodayTransactions(history)
  const financialSummary = calculateFinancialSummary(todayTransactions)
  updateState(
    financialSummary,
    history,
    todayTransactions,
    setInfo,
    setHistory,
    setHistoryFilter,
  )
}
// Update component state
const updateState = (
  financialSummary,
  history,
  todayTransactions,
  setInfo,
  setHistory,
  setHistoryFilter,
) => {
  setInfo(financialSummary)
  setHistory(history)
  setHistoryFilter({
    index: 7,
    data: [...todayTransactions].reverse(),
  })
}

export default processDealHistory
