import React, { useEffect, useState } from 'react';
import Datafeed from '../../data/datafeed';
import { widget } from '../../library/charting_library';
import ChartBackground from './ChartBackground';
import onTick from '../../data/onTick';
import useLastTick from '../../data/useLastTick';

let temp = null;
let savedData = [];

function loadTemplate(userId, symbol) {
  let templateData = localStorage.getItem(userId);
  let listTemplate = JSON.parse(templateData);
  if (listTemplate != null) {
    const indexOfObject = listTemplate.findIndex((object) => {
      return object.symbol === symbol;
    });

    if (indexOfObject != -1) {
      let saved_data = listTemplate[indexOfObject]['template'];
      return saved_data;
    }
  } else {
    return null;
  }
}

const ChartContainer = ({ interval = 15, userId = 'chart_iuxmarkets_01', symbol }) => {
  const { getLastTick } = useLastTick();
  const tvRef = React.useRef();
  const [tvWidget, setTVWidget] = useState(null);
  const onSubscribe = onTick();
  savedData = loadTemplate(userId, symbol);

  if (savedData != null) {
    interval = savedData['charts'][0]['panes'][0]['sources'][0]['state'].interval;
  }

  useEffect(() => {
    if (symbol) {
      if (!tvRef.current) {
        return;
      }
      const widgetOptions = {
        symbol: symbol,
        datafeed: Datafeed({ onSubscribe, getLastTick }),
        interval: interval,
        container: tvRef.current,
        library_path: '/charting_library/',
        locale: 'en',
        disabled_features: [
          'use_localstorage_for_settings',
          'header_symbol_search',
          'header_compare',
          'header_screenshot',
          'header_saveload',
          'timeframes_toolbar',
          'popup_hints'
          // 'left_toolbar'
        ], //'timeframes_toolbar'
        enabled_features: [
          'side_toolbar_in_fullscreen_mode',
          'hide_left_toolbar_by_default',
          'always_show_legend_values_on_mobile',
          'studies_extend_time_scale'
        ],
        // charts_storage_url: defaultProps.chartsStorageUrl,
        // charts_storage_api_version: defaultProps.chartsStorageApiVersion,
        client_id: 'chart.iuxmarkets.com',
        user_id: userId,
        fullscreen: false,
        autosize: true,
        timezone: 'Europe/London',
        loading_screen: {
          backgroundColor: '#0f1018'
        },
        auto_save_delay: 2,
        theme: 'dark',
        overrides: {
          'mainSeriesProperties.showPriceLine': false,
          'scalesProperties.showSeriesLastValue': false,
          'paneProperties.vertGridProperties.color': '#000000',
          'paneProperties.horzGridProperties.color': '#000000',
          'symbolWatermarkProperties.transparency': 90,
          'scalesProperties.textColor': '#fff',
          'paneProperties.backgroundType': 'solid',
          'paneProperties.background': '#000000'
        },
        time_frames: [
          { text: '4h', resolution: '240', description: '4h' },
          { text: '1D', resolution: '1D', description: '1D' },
          { text: '1W', resolution: '1W', description: '1W' }
        ],
        custom_css_url: '/style/css/style.css',
        saved_data: savedData
      };

      temp = new widget(widgetOptions);
      window.tvWidget = tvWidget;
      setTVWidget(temp);
      temp.onChartReady(() => {
        temp.applyOverrides({
          'mainSeriesProperties.showPriceLine': false,
          'scalesProperties.showSeriesLastValue': false,
          'scalesProperties.fontSize': 10,
          'paneProperties.axisProperties.autoScale': true
        });
        temp.headerReady().then(() => {
          // $('iframe').contents().find('.control-bar--hidden').css('visibility', 'visible');
          // $('iframe').contents().find('.control-bar--hidden').css('opacity', '1');
          // $('iframe')
          //   .contents()
          //   .find('.js-btn-group-reset-scale .js-btn-reset')
          //   .css('visibility', 'visible');
          // $('iframe')
          //   .contents()
          //   .find('.js-btn-group-reset-scale .js-btn-reset')
          //   .css('opacity', '1');
          const button = temp.createButton();
          // button.setAttribute('title', 'Tool');
          // button.setAttribute('id', 'testHide');
          button.classList.add('data-role', 'button');
          button.addEventListener('click', () => {
            temp.activeChart().executeActionById('drawingToolbarAction');
          });
          button.innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="icon_tool_24px" transform="translate(-221 -403)"><path id="Path_25665" data-name="Path 25665" d="M0,0H24V24H0Z" transform="translate(221 403)" fill="none"/><path id="icon_tool_24px-2" data-name="icon_tool_24px" d="M21.966,11.133A8.365,8.365,0,0,0,6.182,6.572H2.531A.534.534,0,0,0,2,7.1v9.8a.534.534,0,0,0,.53.53H8.662L6.482,21.216a.511.511,0,0,0-.05.4.5.5,0,0,0,.25.32.5.5,0,0,0,.27.07H21.286a.534.534,0,0,0,.53-.53.485.485,0,0,0-.07-.26l-2.611-4.521a8.32,8.32,0,0,0,2.851-5.551Zm-14.1,9.813L14.1,10.123l6.252,10.823Zm6.7-12.153a.536.536,0,0,0-.35-.23c-.04,0-.07-.01-.11-.01a.529.529,0,0,0-.3.09.608.608,0,0,0-.16.16l-.79,1.36V7.1a.534.534,0,0,0-.53-.53H7.392a7.329,7.329,0,0,1,6.242-3.511h0a7.308,7.308,0,0,1,7.3,7.3,7.324,7.324,0,0,1-2.371,5.391L14.544,8.792ZM11.8,7.642V12L9.282,16.374H3.061V7.642H11.8Z" transform="translate(221 402.994)" fill="#fff"/></g></svg>';
        });
      });
      return () => {
        if (temp !== null) {
          temp.remove();
          temp = null;
        }
      };
    }
  }, []);

  return (
    <>
      <div
        id="port"
        style={{ transition: 'all 0.5s ease' }}
        ref={tvRef}
        className="TVChart prevent-select"
      />
      <ChartBackground tvWidget={tvWidget} symbol={symbol} />
    </>
  );
};

export default ChartContainer;
