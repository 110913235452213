import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LeftPanel from '../../components/panel/left/index.js';
import TradeDialog from '../../components/trading-dialog/components/TradeDialog.js';
import ChartContainer from '../../components/TVChartContainer/ChartContainer.js';
import DrawerAccount from '../../components/accounts/drawer.js';
import HistoryPanel from '../../components/trading-histories/HistoryPanel.js';
import TopPanel from '../../components/panel/top/index.js';
import RightPanel from '../../components/panel/right/index.js';
import SubscribeSettingProvider from '../../components/SubscribeSettingProvider.js';
import useAuth from '../../hooks/useAuth.js';
import { useAppDispatch, useAppSelector } from '../../redux/app.js';
import EditPosition from '../../components/trading-histories/EditPosition.js';
import TradeContextProvider from '../../components/trading-dialog/context/TradeContextProvider.js';
import useLastTick from '../../data/useLastTick.js';
import Position from '../../components/trading-histories/position/index.js';
import Deals from '../../components/trading-histories/history/deals.js';
import Orders from '../../components/trading-histories/history/orders.js';
import Positions from '../../components/trading-histories/history/positions.js';
import initializeMenuSymbol from '../../components/dashboard/initializeMenuSymbol.js';
import cn from '../../utilities/cn';

const Dashboard = () => {
  const { isAuthenticated, activeAccount } = useAuth();
  const { getAllLastTick } = useLastTick();
  const edit = useAppSelector((state) => state.editDialog);
  const dispatch = useAppDispatch();
  // const { subscribeStat } = StatsData();
  const tradeDialog = useSelector(({ tradeDialog }) => tradeDialog);
  const active = useSelector((state) => state.symbol?.active);
  const [loading, setLoading] = useState(true);
  const [activeSymbol, setActive] = useState(null);
  const [historyType, setType] = useState(2);
  const [dialog, setDialog] = useState(false);
  const [screen, setScreen] = useState(false);

  useEffect(() => {
    getAllLastTick();
  }, []);

  useEffect(() => {
    initializeMenuSymbol({ screen, isAuthenticated, activeAccount, dispatch, setLoading });
  }, [screen, isAuthenticated]);

  useEffect(() => {
    setActive(active);
  }, [active]);

  if (!loading) {
    return (
      <div className="prevent-select">
        <TopPanel />
        <div className="flex h-[100vh]">
          <TradeContextProvider>
            <div className="flex-[1] max-w-[70px] h-full">
              <LeftPanel />
              <TradeDialog type={tradeDialog.type} />
              <Position />
            </div>
            <div className="hidden md:block flex-[1] h-full w-[185px] fixed top-0 right-0 z-[1] pt-[65px]">
              <RightPanel />
            </div>
            <SubscribeSettingProvider>
              {edit?.type ? <EditPosition /> : <div />}
            </SubscribeSettingProvider>
          </TradeContextProvider>
          <div
            className={cn('flex-[2] mr-0 md:mr-[185px] bg-black', {
              'ml-[400px]': tradeDialog.isOpen
            })}>
            {activeSymbol && <ChartContainer symbol={activeSymbol} />}
          </div>
        </div>
        <HistoryPanel setType={setType} setDialog={setDialog}>
          {historyType === 2 && <Positions dialog={dialog} setDialog={setDialog} />}
          {historyType === 3 && <Orders dialog={dialog} setDialog={setDialog} />}
          {historyType === 1 && <Deals dialog={dialog} setDialog={setDialog} />}
        </HistoryPanel>
        <DrawerAccount />
      </div>
    );
  }
  return <div />;
};

export default Dashboard;
