import React from 'react';

const LoadingProvider = () => {
  return (
    <div
      className={`bg-[url(/public/img-landing2.svg)] z-10 w-full h-full bg-cover bg-center fixed left-0 top-0 transition-all`}>
      <div className="flex w-full h-full left-0 top-0 bg-[#00000075] z-10">
        <div className="m-auto">
          <div className="wrapper">
            <div className="loading-text">
              <h1 className="text-white text-2xl text-center">
                LOADING
                <span className="dot-one"> .</span>
                <span className="dot-two"> .</span>
                <span className="dot-three"> .</span>
              </h1>
              <div className="text-white text-center mt-2">
                Please wait. Required data is being loaded.
              </div>
            </div>
          </div>
          {/* <div className="loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <img
              src="/images/iuxtrade/IUXTrade-icon.png"
              className="fixed w-[100px] h-[100px] left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[50%]"
            /> */}
        </div>
      </div>
    </div>
  );
};

export default LoadingProvider;
