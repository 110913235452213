import { StorageService } from './storageService';
import google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import ListArray from '../../data/listArray';

export const handleTokenResponse = async (response, working, index, data) => {
  const lisArray = new ListArray();
  const request = new google_protobuf_empty_pb.Empty();
  const serverData = {
    server: working[index]?.api_server,
    accountId: working[index]?.id,
    token: response.u[0]
  };

  StorageService.setItem('trade.server', serverData.server);
  StorageService.setItem('iuxmarkets.active.account', serverData.accountId);
  StorageService.setItem('trade.token', serverData.token);

  if (!window.electron) {
    await lisArray.getListArray(request, {
      Authorization: 'Bearer ' + serverData.token
    });
  }

  return {
    isAuthenticated: true,
    isInitialized: false,
    metatrader: data.data,
    activeAccount: serverData.accountId
  };
};
