import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingProvider from '../loading/LoadingProvider';
import GoToApp from '../panel/GoToApp';
import Footer from './Footer';

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="login" />;
  }

  if (isInitialized) {
    if (window.location.origin.includes('iuxmarkets')) {
      window.location.replace('https://trading.iuxtrade.com');
    }
    return <LoadingProvider />;
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (window.innerWidth <= 1024) {
    return (
      <div>
        <div className="px-4 lg:px-8 pb-8 py-10 container">
          <GoToApp />
        </div>
        <Footer />
      </div>
    );
  }

  return <>{children}</>;
}
