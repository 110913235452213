import './index.css';
import React, { memo, useEffect, useCallback } from 'react';
import emitter from '../../utilities/emitter';
import { useAppDispatch, useAppSelector } from '../../redux/app';
import { CreatePositionLine } from './position';
import { createOrdersLine } from './order';
import TemplateService from './TemplateService';
import ChartUtils from './ChartUtils';
import PriceLineManager from './PriceManager';
import { symbol } from 'prop-types';

const { SymbolName } = require('../../grpc/generated/symbol_pb');
const USER_ID = 'chart_iuxmarkets_01';

const ChartBackground = memo(({ tvWidget }) => {
  const dispatch = useAppDispatch();
  const active = useAppSelector((state) => state.symbol.active);
  const positions = useAppSelector(({ positions }) => positions?.positions);
  const orders = useAppSelector(({ orders }) => orders?.orders);
  const requestName = new SymbolName();

  const handleTickData = useCallback(
    (data) => {
      try {
        const activeSymbol = tvWidget.activeChart().symbolExt()?.symbol;
        if (!data[activeSymbol]) return;

        const priceLineManager = new PriceLineManager(tvWidget);
        priceLineManager.updatePriceLines(activeSymbol, data);
      } catch (error) {
        console.error('Error handling tick data:', error);
        // Consider implementing a more graceful error recovery strategy
        // instead of immediate reload: window.location.reload();
      }
    },
    [tvWidget]
  );

  const initializeChart = useCallback(() => {
    if (!tvWidget) return;

    tvWidget.onChartReady(() => {
      // Initialize lines
      CreatePositionLine(positions, tvWidget);
      createOrdersLine(orders, tvWidget, active?.toString());

      // Setup auto-save
      tvWidget.subscribe('onAutoSaveNeeded', () => {
        tvWidget.save((saveObject) => {
          const symbol = saveObject.charts[0].panes[0].sources[0].state.symbol;
          TemplateService.save(USER_ID, symbol, saveObject);
        });
      });

      // Set symbol
      const currentSymbol = ChartUtils.getSymbol(tvWidget.activeChart().symbol());
      requestName.setName(currentSymbol);

      if (currentSymbol !== active?.toString()) {
        tvWidget.activeChart().setSymbol(active?.toString());
      }

      // Subscribe to tick data
      emitter.instance.on('TickData', handleTickData);
    });
  }, [tvWidget, active, positions, orders, handleTickData]);

  useEffect(() => {
    initializeChart();

    return () => {
      emitter.instance.off('TickData', handleTickData);
    };
  }, [initializeChart, handleTickData]);

  return <div />;
});

ChartBackground.displayName = 'ChartBackground';

export default ChartBackground;
