import React, { memo, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import DateInput from './components/inputs/DateInput'
import { HistoryContext } from './HistoryPanel'

const DateFilter = memo(({ submitDate }) => {
  const { date, handleDate } = useContext(HistoryContext)
  const dateFromRef = useRef(null)
  const dateToRef = useRef(null)

  return (
    <div className="px-5 pb-5 pt-4 border-t border-t-[#2f2f2f]">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
        <DateInput
          label="From"
          inputRef={dateFromRef}
          onChange={(value) => handleDate(value, 'from')}
          defaultValue={date.from ?? null}
        />
        <DateInput
          label="To"
          inputRef={dateToRef}
          onChange={(value) => handleDate(value, 'to')}
          defaultValue={date.to ?? null}
        />
      </div>
      <div className="text-center mt-5">
        <button
          disabled={date.from === '' || date.to === ''}
          onClick={() => submitDate(date)}
          className="px-5 py-2 w-[100px] rounded-md bg-[#169376] disabled:bg-[#262626] disabled:text-[#595959] text-white">
          Submit
        </button>
      </div>
    </div>
  )
})

DateFilter.displayName = 'DateFilter'

DateFilter.propTypes = {
  submitDate: PropTypes.func.isRequired,
}

export default DateFilter
