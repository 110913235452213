import { FILTER_PERIODS } from '../constant';
import moment from 'moment/moment';

export const getTimestampForPeriod = (period) => {
  const periodMap = {
    [FILTER_PERIODS.LAST_WEEK]: () => moment().subtract(7, 'day').unix(),
    [FILTER_PERIODS.LAST_MONTH]: () => moment().subtract(1, 'month').unix(),
    [FILTER_PERIODS.LAST_3_MONTHS]: () => moment().subtract(3, 'month').unix(),
    [FILTER_PERIODS.LAST_6_MONTHS]: () => moment().subtract(6, 'month').unix(),
    [FILTER_PERIODS.LAST_YEAR]: () => moment().subtract(1, 'year').unix(),
    [FILTER_PERIODS.ALL_TIME]: () => 0,
    [FILTER_PERIODS.TODAY]: () => moment().unix(),
    default: () => moment().unix()
  };

  return (periodMap[period] || periodMap.default)();
};
