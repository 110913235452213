import React, { useContext, useState } from 'react';
import BuySellButton from '../components/ActionButton';
import LotSizeController from '../components/LotSizeController';
import StopLossAndTakeProfit from '../components/StopLossAndTakeProfit';
import { TradeContext } from '../context/TradeContextProvider';
import InputPrice from '../components/InputPrice';
import { useAppDispatch, useAppSelector } from '../../../redux/app';
import { setPayload } from '../../../redux/reducer/payload';
import ExpirationDate from '../components/ExpirationDate';
import Expiration from '../components/Expiration';

const BuyStopFunction = ({ isBuyStop }) => {
  const { lotRef } = useContext(TradeContext);
  const [error, setError] = useState(false);
  const payload = useAppSelector((state) => state.payload?.payload);
  const dispatch = useAppDispatch();

  return (
    <div>
      <LotSizeController lotRef={lotRef} setError={setError} />
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Price</div>
        <div></div>
      </div>
      <InputPrice validate={isBuyStop ? 4 : 5} className="mx-[10px] mt-2" />
      <div className="px-[12px]">
        <StopLossAndTakeProfit />
      </div>
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Expiration</div>
        <div></div>
      </div>
      <div style={{ margin: 10 }}>
        <Expiration />
      </div>
      {payload.type_time >= 2 ? <ExpirationDate className="mt-2" /> : null}
      <BuySellButton typeBuy={isBuyStop ? 4 : 5} />
    </div>
  );
};

export default BuyStopFunction;
