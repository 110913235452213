import React, { memo, useMemo } from 'react';
import AccountInfo from './AccountInfo';
import useAuth from '../../../hooks/useAuth';
import { ORIGIN, TYPE_SERVER } from '../../../constants';

const accountTypeName = { Standard: 'Standard', StandardBonus: 'Standard+', Cent: 'Cent' };

const TopMenuAccountInfo = memo(() => {
  const { activeAccount, metatrader, getFirebaseCustom, user } = useAuth();

  const account = useMemo(
    () =>
      metatrader?.reduce((a, b) => {
        return {
          ...a,
          [b.id]: b
        };
      }, {}),
    [metatrader]
  );

  if (!account) return;
  return (
    <div className="flex items-center mr-[14px]">
      <div className="border-r border-r-[#585858] px-[14px]">
        <div className="text-white flex items-center justify-start font-bold text-sm">
          <span className="mr-2">Account:</span>
          <span>{account[activeAccount]?.account_number}</span>
        </div>
        <div className="font-bold text-sm">
          <span
            className={`capitalize ${
              account[activeAccount]?.api_server === 'demo' ? 'text-[#ff6c00]' : 'text-[#169376]'
            }`}>
            {TYPE_SERVER[account[activeAccount]?.api_server]
              ? TYPE_SERVER[account[activeAccount]?.api_server]
              : account[activeAccount]?.api_server}
          </span>
          <span className="capitalize text-sm text-[#c1c1c1]">{` - ${
            accountTypeName[account[activeAccount]?.account_type] ??
            account[activeAccount]?.account_type
          }`}</span>
        </div>
      </div>
      <AccountInfo />
      {account[activeAccount]?.type === 'real' && (
        <button
          className="deposit"
          onClick={() =>
            getFirebaseCustom().then((token) => {
              window.open(
                `${ORIGIN[window.location.origin]}/ja/wallet/fund/deposit/${
                  account[activeAccount]?.account_number
                }/${token}`
              );
            })
          }
          rel="noreferrer">
          <span className="text-space">DEPOSIT</span>
        </button>
      )}
    </div>
  );
});

TopMenuAccountInfo.displayName = 'TopMenuAccountInfo';
export default TopMenuAccountInfo;
