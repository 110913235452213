import { mapActionType, mapColor } from './actions';

// Constants for better maintainability
const COMPLETED_ORDER_TYPES = [0, 1];
const DEFAULT_VALUES = {
  COMMENT: 'No Comment',
  ID: 0,
  PRICE: 0
};

class OrderManager {
  constructor() {
    this.orderList = [];
    this.orderLine = [];
  }

  drawOrderLine(price, tvWidget, digit, action) {
    let order = `${mapActionType(action)} ${price.toFixed(digit)}`;
    return tvWidget.activeChart().createShape(
      { price: price },
      {
        shape: 'horizontal_line',
        disableSelection: true,
        disableSave: true,
        disableUndo: true,
        showInObjectsTree: false,
        text: order,
        zOrder: 'top',
        lock: true,
        overrides: {
          showLabel: true,
          showTime: false,
          linestyle: 0,
          linecolor: mapColor(action),
          horzLabelsAlign: 'left',
          vertLabelsAlign: 'top',
          textcolor: mapColor(action),
          showPrice: true
        }
      }
    );
  }

  createOrderObject(orderData) {
    const [
      ,
      id = DEFAULT_VALUES.ID,
      order = DEFAULT_VALUES.ID,
      symbol = '',
      digits = DEFAULT_VALUES.ID,
      contractSize = DEFAULT_VALUES.ID, // Skip unused indices
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      type, // Skip unused indices
      ,
      ,
      priceOrder,
      priceTrigger = DEFAULT_VALUES.PRICE,
      priceCurrent,
      priceSL = DEFAULT_VALUES.PRICE,
      priceTP = DEFAULT_VALUES.PRICE,
      volumeInitial = DEFAULT_VALUES.ID, // Skip unused indices
      ,
      volumeCurrent, // Skip unused indices
      ,
      comment = DEFAULT_VALUES.COMMENT, // Skip unused indices
      ,
      ,
      ,
      ,
      digitsCurrency = DEFAULT_VALUES.ID
    ] = orderData;

    return {
      id,
      order,
      symbol,
      digits,
      digitsCurrency,
      contractSize,
      type,
      priceOrder,
      priceTrigger,
      priceCurrent,
      priceSL,
      priceTP,
      volumeInitial,
      volumeCurrent,
      comment
    };
  }

  getActiveSymbol(tvWidget) {
    const symbolExt = tvWidget.activeChart().symbolExt();
    return symbolExt?.description || symbolExt?.symbol;
  }

  filterActiveOrders(orders, activeSymbol) {
    return orders.filter(
      (item) => !COMPLETED_ORDER_TYPES.includes(item[13]) && item[3] === activeSymbol
    );
  }

  handleSymbolChange(result, activeSymbol, previousSymbol) {
    if (activeSymbol === previousSymbol) return false;

    this.orderList = result.map((order) => this.createOrderObject(order));
    return true;
  }

  updateExistingOrders(tvWidget, result) {
    for (const currentOrder of result) {
      const existingOrder = this.orderList.find((order) => order.id === currentOrder[16]);

      if (existingOrder && existingOrder.priceOrder !== currentOrder[16]) {
        const lineIndex = this.orderLine.findIndex((line) => line.id === existingOrder.id);

        if (lineIndex !== -1) {
          const orderLine = this.orderLine[lineIndex];
          tvWidget.activeChart().removeEntity(orderLine.orderLineChart);

          if (currentOrder[16] !== undefined && !COMPLETED_ORDER_TYPES.includes(currentOrder[13])) {
            const newLine = this.drawOrderLine(
              currentOrder[16],
              tvWidget,
              currentOrder[4],
              currentOrder[13]
            );

            existingOrder.priceOrder = currentOrder[16];
            this.orderLine[lineIndex].orderLineChart = newLine;
          }
        }
      }
    }
  }

  removeClosedOrders(tvWidget, result) {
    this.orderList = this.orderList.filter((existingOrder) => {
      const orderStillExists = result.some((newOrder) => newOrder[1] === existingOrder.id);

      if (!orderStillExists) {
        const lineIndex = this.orderLine.findIndex((line) => line.id === existingOrder.id);
        if (lineIndex !== -1) {
          tvWidget.activeChart().removeEntity(this.orderLine[lineIndex].orderLineChart);
          this.orderLine.splice(lineIndex, 1);
        }
        return false;
      }
      return true;
    });
  }

  addNewOrders(tvWidget, result) {
    for (const order of result) {
      if (!this.orderList.some((existing) => existing.id === order[1])) {
        const newOrder = this.createOrderObject(order);
        this.orderList.push(newOrder);

        if (newOrder.priceOrder !== undefined && !COMPLETED_ORDER_TYPES.includes(newOrder.type)) {
          try {
            const orderLine = this.drawOrderLine(
              newOrder.priceOrder,
              tvWidget,
              newOrder.digits,
              newOrder.type
            );

            this.orderLine.push({
              id: newOrder.id,
              volume: newOrder.priceOrder,
              orderLineChart: orderLine
            });
          } catch (error) {
            console.error('Error drawing order line:', error);
          }
        }
      }
    }
  }

  createOrdersLine(orders, tvWidget, previousSymbol) {
    try {
      const activeSymbol = this.getActiveSymbol(tvWidget);
      if (!activeSymbol) return;

      const result = this.filterActiveOrders(orders, activeSymbol);

      if (this.handleSymbolChange(result, activeSymbol, previousSymbol)) {
        return activeSymbol;
      }

      if (result.length !== this.orderList.length) {
        this.removeClosedOrders(tvWidget, result);
        this.addNewOrders(tvWidget, result);
      } else {
        this.updateExistingOrders(tvWidget, result);
      }

      return previousSymbol;
    } catch (error) {
      console.error('Error in createOrdersLine:', error);
      return previousSymbol;
    }
  }
}

export const orderManager = new OrderManager();
export const createOrdersLine = orderManager.createOrdersLine.bind(orderManager);
