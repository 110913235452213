import { mapActionType, mapColor } from './actions';

class PositionLineManager {
  constructor() {
    this.positionList = [];
    this.positionLine = [];
    this.previousSymbol = null;
  }

  createPositionLines(positions, tvWidget) {
    try {
      const activeChart = tvWidget.activeChart();
      const symbolExt = activeChart.symbolExt();
      const activeSymbol = symbolExt?.description || symbolExt?.symbol;

      if (!activeSymbol) return;

      const activePositions = positions.filter((pos) => pos[1] === activeSymbol);

      if (activeSymbol !== this.previousSymbol) {
        this.handleSymbolChange(activePositions, activeSymbol);
      } else {
        this.updateExistingPositions(activePositions, tvWidget);
      }

      this.drawPositionLines(tvWidget);
    } catch (error) {
      console.error('Error creating position lines:', error);
    }
  }

  handleSymbolChange(activePositions, newSymbol) {
    this.previousSymbol = newSymbol;
    this.positionList = activePositions.map((pos) => this.createPositionObject(pos));
  }

  createPositionObject(positionData) {
    const [
      position,
      symbol,
      action = 0,
      digits,
      digitsCurrency,
      contractSize,
      timeCreate,
      timeUpdate,
      priceOpen,
      priceCurrent,
      priceSL,
      priceTP,
      volume,
      profit,
      reason
    ] = positionData;

    return {
      position,
      symbol,
      action,
      digits,
      digitsCurrency,
      contractSize,
      timeCreate,
      timeUpdate,
      priceOpen,
      priceCurrent,
      priceSL,
      priceTP,
      volume,
      profit,
      reason
    };
  }

  updateExistingPositions(activePositions, tvWidget) {
    if (!this.positionList.length) {
      this.positionList = activePositions.map((pos) => this.createPositionObject(pos));
      return;
    }

    if (activePositions.length !== this.positionList.length) {
      this.handlePositionCountChange(activePositions, tvWidget);
    } else {
      this.updatePositionDetails(activePositions, tvWidget);
    }
  }

  handlePositionCountChange(activePositions, tvWidget) {
    if (activePositions.length > this.positionList.length) {
      this.addNewPositions(activePositions, tvWidget);
    } else {
      this.removeClosedPositions(activePositions, tvWidget);
    }
  }

  addNewPositions(activePositions, tvWidget) {
    const newPositions = activePositions.filter(
      (pos) => !this.positionList.some((existing) => existing.position === pos[0])
    );

    newPositions.forEach((pos) => {
      const position = this.createPositionObject(pos);
      this.positionList.push(position);

      if (position.priceSL) {
        this.drawSLLine(position.priceSL, tvWidget, position.digits);
      }
      if (position.priceTP) {
        this.drawTPLine(position.priceTP, tvWidget, position.digits);
      }
    });
  }

  removeClosedPositions(activePositions, tvWidget) {
    const activePositionIds = new Set(activePositions.map((pos) => pos[0]));

    this.positionList = this.positionList.filter((pos) => {
      if (!activePositionIds.has(pos.position)) {
        this.removePositionLines(pos.position, tvWidget);
        return false;
      }
      return true;
    });
  }

  removePositionLines(positionId, tvWidget) {
    const lineIndex = this.positionLine.findIndex((line) => line.position === positionId);
    if (lineIndex === -1) return;

    const { pLine, lineSl, lineTp } = this.positionLine[lineIndex];

    if (lineSl) tvWidget.activeChart().removeEntity(lineSl);
    if (lineTp) tvWidget.activeChart().removeEntity(lineTp);
    if (pLine) pLine.remove();

    this.positionLine.splice(lineIndex, 1);
  }

  updatePositionDetails(activePositions, tvWidget) {
    activePositions.forEach((pos, index) => {
      const currentPosition = this.positionList[index];
      const lineIndex = this.positionLine.findIndex(
        (line) => line.position === currentPosition.position
      );

      // this.updateVolume(pos, currentPosition, lineIndex);
      // this.updateStopLoss(pos, currentPosition, lineIndex, tvWidget);
      // this.updateTakeProfit(pos, currentPosition, lineIndex, tvWidget);
    });
  }

  drawPositionLines(tvWidget) {
    this.positionList.forEach((position) => {
      if (this.positionLine.some((line) => line.position === position.position)) return;

      const quantity = this.formatQuantityText(position.priceOpen, position.volume);
      const actionText = mapActionType(position.action);

      try {
        const positionLine = this.createPositionLine(tvWidget, position, quantity, actionText);
        const sl = position.priceSL
          ? this.drawSLLine(position.priceSL, tvWidget, position.digits)
          : null;
        const tp = position.priceTP
          ? this.drawTPLine(position.priceTP, tvWidget, position.digits)
          : null;

        this.positionLine.push({
          position: position.position,
          volume: position.volume,
          pLine: positionLine,
          lineSl: sl,
          lineTp: tp
        });
      } catch (error) {
        console.error('Error drawing position line:', error);
      }
    });
  }

  createPositionLine(tvWidget, position, quantity, text) {
    return tvWidget
      .activeChart()
      .createPositionLine(false)
      .setText(text)
      .setQuantity(quantity)
      .setPrice(position.priceOpen)
      .setExtendLeft(false)
      .setLineStyle(0)
      .setLineLength(100)
      .setLineColor(mapColor(position.action))
      .setBodyBorderColor('rgba(255, 255, 255, 0.95)')
      .setQuantityBorderColor(mapColor(position.action))
      .setQuantityBackgroundColor(mapColor(position.action));
  }

  drawSLLine(price, tvWidget, digit) {
    return this.createHorizontalLine(price, tvWidget, digit, 'SL', '#C94644');
  }

  drawTPLine(price, tvWidget, digit) {
    return this.createHorizontalLine(price, tvWidget, digit, 'TP', '#039981');
  }

  createHorizontalLine(price, tvWidget, digit, type, color) {
    const text = `${type} at ${price.toFixed(digit)}`;
    return tvWidget.activeChart().createShape(
      { price },
      {
        shape: 'horizontal_line',
        disableSelection: true,
        disableSave: true,
        disableUndo: true,
        showInObjectsTree: false,
        text,
        lock: true,
        zOrder: 'bottom',
        overrides: {
          showLabel: true,
          showTime: false,
          linestyle: 2,
          linecolor: color,
          horzLabelsAlign: 'left',
          vertLabelsAlign: 'bottom',
          textcolor: color,
          showPrice: false
        }
      }
    );
  }

  formatQuantityText(priceOpen, volume) {
    const lot = volume / 10000;
    return `${lot} Lot at ${priceOpen}`;
  }
}

export const positionLineManager = new PositionLineManager();
export const CreatePositionLine = (positions, tvWidget) =>
  positionLineManager.createPositionLines(positions, tvWidget);
