import { serverApiConfig } from '../config.js';
import { getGrpcResponse } from '../utilities/getGrpcResponse';

const moment = require('moment-timezone');
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');

export function getNextBarTime(barTime, tf) {
  let unix_timestamp = barTime;
  let date = new Date(unix_timestamp);

  let nextDateTime;

  if (tf === '1D') {
    nextDateTime = moment(date).tz('Europe/London').add(1, 'd').startOf('date').toDate();
  } else if (tf === '1W') {
    nextDateTime = moment(date).tz('Europe/London').add(1, 'w').startOf('date').toDate();
  } else if (tf === '1M') {
    nextDateTime = moment(date).tz('Europe/London').add(1, 'm').startOf('date').toDate();
  } else {
    // Adjust the minutes to the nearest interval based on resolution
    const resolution = parseInt(tf);
    const minutes = moment(date).minute();
    const roundedMinutes = Math.floor(minutes / resolution) * resolution;
    nextDateTime = moment(date)
      .minute(roundedMinutes)
      .second(0)
      .millisecond(0)
      .add(resolution, 'minutes')
      .toDate();
  }

  const timestampMillisecond = nextDateTime.getTime();

  return timestampMillisecond;
}

export default class SubscribeOnTick {
  async subScribeBidAsk(request, metadata) {
    let symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    await symbolClient.tickSubscribeArray(request, metadata, (err, response) => {
      if (response && getGrpcResponse(response)[0] === 200) {
        console.log(getGrpcResponse(response)[1]);
      } else {
        console.log('error => ', err);
      }
    });
  }

  async unSubScribeBidAsk(request, metadata) {
    let symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    await symbolClient.tickUnsubscribeArray(request, metadata, (err, response) => {
      if (getGrpcResponse(response)[0] === 200) {
        console.log(getGrpcResponse(response)[1]);
      } else {
        console.log('error => ', err);
      }
    });
  }

  async singleSubScribe(request, metadata) {
    let symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    await symbolClient.tickSubscribe(request, metadata, (err, response) => {
      if (getGrpcResponse(response)[0] === 200) {
        console.log(getGrpcResponse(response)[1]);
      } else {
        console.log('error => ', err);
      }
    });
  }

  async singleUnSubScribe(request, metadata) {
    let symbolClient = new SymbolClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    await symbolClient.tickUnsubscribe(request, metadata, (err, response) => {
      if (getGrpcResponse(response)[0] === 200) {
        console.log(getGrpcResponse(response)[1]);
      } else {
        console.log('error => ', err);
      }
    });
  }
}
