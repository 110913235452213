import React, { memo, useContext, useMemo } from 'react';
import { useAppSelector } from '../../../redux/app';
import { makeBidAskBySymbolSelector } from '../../../redux/selector';
import { TradeContext } from '../../trading-dialog/context/TradeContextProvider';

const Buy = memo(({ handleBuySell, digit, symbol }) => {
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, []);
  const ask = useAppSelector((state) => getBidAsk(state, symbol ?? ''));
  return (
    <button className="entry-right hover:bg-[#084497] relative" onClick={() => handleBuySell(ask)}>
      <div>BUY</div>
      <div className="text-xs">{ask ? ask.ask.toFixed(digit) : (0).toFixed(digit)}</div>
    </button>
  );
});

Buy.displayName = 'Buy';

const Sell = memo(({ handleBuySell, digit, symbol }) => {
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, []);
  const bid = useAppSelector((state) => getBidAsk(state, symbol ?? ''));
  return (
    <button className="entry-sell-right hover:bg-[#ac2e15]" onClick={() => handleBuySell(bid)}>
      <div>SELL</div>
      <div className="text-xs">{bid ? bid.bid.toFixed(digit) : (0).toFixed(digit)}</div>
    </button>
  );
});

Sell.displayName = 'Sell';

const RightButtonAction = memo(() => {
  const { instrumentInfo, handleExecute } = useContext(TradeContext);
  // makeBidAskBySymbolSelector
  return (
    <div>
      <div style={{ height: '20px' }}></div>
      <Buy
        digit={instrumentInfo?.digit}
        symbol={instrumentInfo?.symbol}
        handleBuySell={(e) => handleExecute(0, 200, undefined, e)}
      />
      <div style={{ height: '20px' }}></div>
      <Sell
        digit={instrumentInfo?.digit}
        symbol={instrumentInfo?.symbol}
        handleBuySell={(e) => handleExecute(1, 200, undefined, e)}
      />
    </div>
  );
});

RightButtonAction.displayName = 'RightButtonAction';

export { RightButtonAction };
