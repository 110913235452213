import moment from 'moment/moment';

export const createHistoryObject = (item) => {
  const [
    ticket = 0,
    action = 0,
    timestamp,
    comment = '',
    profit = 0,
    close_price,
    open_time,
    close_time,
    tp = null,
    sl = null,
    open_price,
    volume = 0,
    digits,
    symbol,
    contract_size,
    login,
    swap,
    commission = 0
  ] = item;
  return {
    ticket,
    action,
    timestamp,
    comment,
    profit,
    close_price,
    open_time,
    close_time,
    tp,
    sl,
    open_price,
    volume,
    digits,
    symbol,
    contract_size,
    login,
    swap,
    commission,
    time_format: moment.unix(timestamp).format('YYYY-MM-DD')
  };
};
