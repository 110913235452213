import React, { useMemo } from 'react'
import { makeTradeDialogSelector } from '../../../redux/selector'
import { useAppSelector } from '../../../redux/app'
import OrderTable from './OrderTable'

const Position = () => {
  const getTradeDialog = useMemo(makeTradeDialogSelector, [])
  const tradeDialog = useAppSelector((state) => getTradeDialog(state))

  return <OrderTable type={tradeDialog.type} />
}

export default Position