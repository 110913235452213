import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingProvider from '../loading/LoadingProvider';
import { Trans, useTranslation } from 'react-i18next';
import Navbar from '../Navbar';
import Footer from './Footer';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { t, i18n } = useTranslation();

  if (isAuthenticated) {
    if (window.location.origin === 'https://trading.iuxmarkets.com') {
      window.location.replace('https://trading.iuxtrade.com');
    }
    return <Navigate to="/trade-panel" />;
  }

  if (isInitialized) {
    return <LoadingProvider />;
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <div id="loginForm" className="login-form">
      <div className="px-4 lg:px-8 pb-8 container">
        <Navbar handleChangeLang={changeLanguage} />
        <div className="text-[#aaaaaa] text-md font-sans text-center mb-[40px]">
          <Trans
            t={t}
            i18nKey="head"
            components={{
              bi: <b />
            }}
          />
        </div>
        {children}
      </div>
      <Footer />
    </div>
  );
}
