import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCAb6xChMwqRq7I5SPSiN8QV-VrnJ2ekpM',
  authDomain: 'iuxtrade.firebaseapp.com',
  projectId: 'iuxtrade',
  storageBucket: 'iuxtrade.firebasestorage.app',
  messagingSenderId: '456252954356',
  appId: '1:456252954356:web:7fdaeca2b0b89e60c570a1',
  measurementId: 'G-SNPWNVD2V6'
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const checkUserFirebase = () => {
  return auth.currentUser;
};

export const getuser = async () => {
  return auth?.currentUser?.getIdToken()?.then((userCredential) => {
    return userCredential;
  });
};

export const logoutFirebase = async () => {
  return auth?.signOut();
};
