import React, { memo, useContext, useMemo, useRef } from 'react';
import { usePriceChange } from '../helpers';
import { useAppDispatch, useAppSelector } from '../../../redux/app';
import { TradeContext } from '../context/TradeContextProvider';
import { setPayload } from '../../../redux/reducer/payload';
import { makeBidAskBySymbolSelector } from '../../../redux/selector';
import cn from '../../../utilities/cn';

const InputPrice = memo(({ validate, className, defaultValue, editBidAsk }) => {
  const priceRef = useRef(null);
  const data = useAppSelector((state) => state.payload?.payload);
  const { instrumentInfo } = useContext(TradeContext);
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, []);
  const bidAsk = useAppSelector((state) => getBidAsk(state, instrumentInfo?.symbol ?? ''));
  const dispatch = useAppDispatch();

  const handlePrice = (type) => {
    const price = usePriceChange({
      type: type,
      price_order: data.price_order,
      ask: editBidAsk ? editBidAsk.ask : bidAsk?.ask,
      digits: editBidAsk ? editBidAsk.digit : instrumentInfo?.digit
    });
    dispatch(
      setPayload({
        ...data,
        price_order: price
      })
    );
  };

  const validatePrice = (key) => {
    let price_order;
    switch (key) {
      case 2:
        price_order = editBidAsk ? editBidAsk.ask : bidAsk?.ask;
        break;
      case 3:
        price_order = editBidAsk ? editBidAsk.bid : bidAsk?.bid;
        break;
      case 4:
        price_order = editBidAsk ? editBidAsk.ask : bidAsk?.ask;
        break;
      case 5:
        price_order = editBidAsk ? editBidAsk.bid : bidAsk?.bid;
        break;
      case 6:
        price_order = editBidAsk ? editBidAsk.ask : bidAsk?.ask;
        break;
      case 7:
        price_order = editBidAsk ? editBidAsk.bid : bidAsk?.bid;
        break;
      default:
        break;
    }
    return dispatch(
      setPayload({
        ...data,
        price_order: price_order ?? 0
      })
    );
  };

  return (
    <div className={cn('box-input-lotsize', className)}>
      <div>
        <button className="dec" onClick={() => handlePrice('dec')}>
          -
        </button>
      </div>
      <input
        onChange={({ target: { value } }) => {
          if (value !== '') {
            if (value.startsWith('-')) {
              priceRef.current.value = data.price_order;
            } else {
              dispatch(
                setPayload({
                  ...data,
                  price_order: +value
                })
              );
            }
          } else {
            dispatch(
              setPayload({
                ...data,
                price_order: 0
              })
            );
          }
        }}
        type="number"
        ref={priceRef}
        value={data.price_order <= 0 ? '' : data.price_order}
        placeholder="No Set"
        className="input-left-lotsize m-[10px]"
        onMouseDown={() => {
          if (!defaultValue) {
            if (+data.price_order === 0) {
              validatePrice(validate);
            }
          }
        }}
      />
      <div>
        <button className="inc" onClick={() => handlePrice('inc')}>
          +
        </button>
      </div>
    </div>
  );
});

InputPrice.displayName = 'InputPrice';

export default InputPrice;
