class TemplateService {
  static load(userId, symbol) {
    try {
      const templates = JSON.parse(localStorage.getItem(userId));
      if (!templates) return null;

      const template = templates.find((t) => t.symbol === symbol);
      return template?.template || null;
    } catch (error) {
      console.error('Error loading template:', error);
      return null;
    }
  }

  static save(userId, symbol, saveObject) {
    try {
      const existingData = localStorage.getItem(userId);
      const templates = existingData ? JSON.parse(existingData) : [];

      const templateIndex = templates.findIndex((t) => t.symbol === symbol);
      if (templateIndex !== -1) {
        templates.splice(templateIndex, 1);
      }

      templates.push({ symbol, template: saveObject });
      localStorage.setItem(userId, JSON.stringify(templates));
    } catch (error) {
      console.error('Error saving template:', error);
    }
  }
}

export default TemplateService;
