import React, { createContext, useEffect, useState } from 'react'
import { setTradeDialog } from '../../redux/reducer/dialog'
import { useDispatch } from 'react-redux'
import EventEmitter from '../../utilities/service'
import { setDeals, setHistories, setOrders } from '../../redux/reducer/history'
import UserHistory from '../../data/userHistories'
import { useAppSelector } from '../../redux/app'
import { metadata } from '../../data/metadata'
import { getGrpcResponse } from '../../utilities/getGrpcResponse'
import moment from 'moment/moment'

const { UserHistoryRequest } = require('../../grpc/generated/account_pb')

export const HistoryContext = createContext({})

const HistoryPanel = ({ children, setDialog, setType }) => {
  const [marketOption, setMarketOption] = useState(1)
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState({
    from: '',
    to: '',
  })
  const requestHistory = new UserHistoryRequest()
  const history = new UserHistory()

  useEffect(() => {
    const mode = 2
    history.userHistory(requestHistory, metadata(), mode)
    EventEmitter.subscribe('history', (event) => {
      if (event.data) {
        if (event.mode === 2) {
          dispatch(setHistories(getGrpcResponse(event.data)[1]))
        }
        if (event.mode === 3) {
          dispatch(setOrders(getGrpcResponse(event.data)[3]))
        }
        if (event.mode === 1) {
          dispatch(setDeals(getGrpcResponse(event.data)[2]))
        }
      }
    })
  }, [])

  const handleDate = (value, key) => {
    setDate((prev) => ({
      ...prev,
      [key]: moment(value).format('YYYY-MM-DD'),
    }))
  }

  const handleType = (x) => {
    setType(x)
    history.userHistory(requestHistory, metadata(), x)
  }

  const dispatch = useDispatch()
  const { type } = useAppSelector(({ tradeDialog }) => tradeDialog)

  const List = ['Positions', 'Orders', 'Deals']

  return (
    <div
      style={{
        transform: type === 'history' ? 'translateX(0%)' : 'translateX(-150%)',
        position: 'fixed',
        left: '70px',
        top: '65px',
      }}>
      <div className="border-b border-b-[#232323] h-[40px] bg-[#0d0e15]">
        <div className="grid grid-cols-[70%_15%_15%] h-full">
          <div className="border-r border-r-[#232323] h-full">
            <div className="flex flex-col px-3 relative w-full">
              <div
                className="flex justify-between items-center cursor-pointer h-[40px]"
                onClick={() => setOpen(!open)}>
                <div className="text-white text-sm">{List[marketOption - 1]}</div>
                <div>
                  <svg
                    className={`transition-all ${
                      open ? 'rotate-0' : 'rotate-180'
                    } history-btn-action`}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      {' '}
                      <path
                        d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                        fill="#c1c1c1"></path>
                      {' '}
                    </g>
                  </svg>
                </div>
              </div>
              {open && (
                <div
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                  className="absolute z-10 top-[42px] left-0 bg-[#1E1E1E] border border-[#80808059] w-full flex flex-col rounded-md">
                  {List.map((e, i) => (
                    <div
                      onClick={() => {
                        setMarketOption(i + 1)
                        if (i === 0) handleType(2)
                        if (i === 1) handleType(3)
                        if (i === 2) handleType(1)
                        setOpen(false)
                      }}
                      key={e}
                      className="text-white text-sm px-4 h-[30px] flex items-center hover:bg-[#169376] hover:rounded-md transition-all cursor-pointer">
                      {e}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="border-r border-r-[#232323] h-full flex cursor-pointer"
            onClick={() => setDialog(true)}>
            <svg
              className="m-auto history-btn-action"
              width="13"
              height="13"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.41836 16H13.5828C14.7532 16 15.7043 15.0489 15.7043 13.8785V3.30665C15.7043 2.13628 14.7532 1.18516 13.5828 1.18516H12.7413V0.592582C12.7413 0.266652 12.4746 0 12.1487 0C11.8228 0 11.5561 0.266652 11.5561 0.592582V1.18516H4.44504V0.592582C4.44504 0.266652 4.17834 0 3.85241 0C3.52648 0 3.25983 0.266652 3.25983 0.592582V1.18516H2.41836C1.24799 1.18516 0.296875 2.13628 0.296875 3.30665V13.8785C0.296875 15.0489 1.24799 16 2.41836 16ZM1.48209 3.30665C1.48209 2.79111 1.90282 2.37038 2.41836 2.37038H3.25983V2.96296C3.25983 3.28889 3.52648 3.55554 3.85241 3.55554C4.17834 3.55554 4.445 3.28889 4.445 2.96296V2.37038H11.5561V2.96296C11.5561 3.28889 11.8228 3.55554 12.1487 3.55554C12.4746 3.55554 12.7413 3.28889 12.7413 2.96296V2.37038H13.5828C14.0983 2.37038 14.519 2.79111 14.519 3.30665V5.03704H1.48209V3.30665ZM1.48209 6.22221H14.5191V13.8785C14.5191 14.3941 14.0984 14.8148 13.5829 14.8148H2.41836C1.90282 14.8148 1.48209 14.3941 1.48209 13.8785V6.22221Z"
                fill="#c1c1c1"
              />
            </svg>
          </div>
          <div
            className="border-r border-r-[#232323] h-full flex cursor-pointer"
            onClick={() => {
              dispatch(setTradeDialog({ isOpen: false, type: '' }))
            }}>
            <svg
              className="m-auto history-btn-action"
              width="11"
              height="11"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.781396 16.0001C0.626858 16.0001 0.475783 15.9543 0.347281 15.8685C0.218778 15.7826 0.118621 15.6606 0.0594776 15.5178C0.000334661 15.3751 -0.0151369 15.218 0.0150198 15.0664C0.0451766 14.9148 0.119607 14.7756 0.228896 14.6664L14.6664 0.228853C14.8129 0.0823209 15.0117 0 15.2189 0C15.4261 0 15.6249 0.0823209 15.7714 0.228853C15.9179 0.375385 16.0002 0.574125 16.0002 0.781353C16.0002 0.988581 15.9179 1.18732 15.7714 1.33385L1.3339 15.7714C1.26141 15.844 1.17528 15.9016 1.08047 15.9408C0.985653 15.9801 0.884016 16.0002 0.781396 16.0001Z"
                fill="#c1c1c1"
              />
              <path
                d="M15.2189 16.0001C15.1162 16.0002 15.0146 15.9801 14.9198 15.9408C14.825 15.9016 14.7388 15.844 14.6664 15.7714L0.228853 1.33385C0.0823209 1.18732 0 0.988581 0 0.781353C0 0.574125 0.0823209 0.375385 0.228853 0.228853C0.375385 0.0823209 0.574125 0 0.781353 0C0.988581 0 1.18732 0.0823209 1.33385 0.228853L15.7714 14.6664C15.8806 14.7756 15.9551 14.9148 15.9852 15.0664C16.0154 15.218 15.9999 15.3751 15.9408 15.5178C15.8816 15.6606 15.7815 15.7826 15.653 15.8685C15.5245 15.9543 15.3734 16.0001 15.2189 16.0001Z"
                fill="#c1c1c1"
              />
            </svg>
          </div>
        </div>
      </div>
      <HistoryContext.Provider value={{ date, handleDate }}>
        {children}
      </HistoryContext.Provider>
    </div>
  )
}

export default HistoryPanel
