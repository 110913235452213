import { getMenuFromLocalstorage } from '../utilities/fromStorage.js';
import { serverApiConfig } from '../config.js';
import emitter from '../utilities/emitter';
import { metadata } from './metadata.js';

const moment = require('moment-timezone');
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');

const { SymbolName } = require('../grpc/generated/symbol_pb.js');
const { TickRequest } = require('../grpc/generated/symbol_pb.js');
const { LastTickRequest } = require('../grpc/generated/symbol_pb.js');
const { HistoryClient } = require('../grpc/generated/history_grpc_web_pb.js');
const { OHLCRequest } = require('../grpc/generated/history_pb');

const logMessage = (...messages) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  const now = new Date();
  console.log(`${now.toLocaleTimeString()}.${now.getMilliseconds()}>`, ...messages);
};

const shouldCheckDST = true;
const TZ = 'Europe/London';
const configurationData = {
  supports_search: true,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
  exchanges: [
    {
      value: 'iux',
      name: 'iux',
      desc: 'IUX Markets Exchange'
    }
  ],
  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto'
    }
  ]
};

export function groupDataByTimeframe(data, intervalMinutes) {
  const intervalMs = intervalMinutes * 60 * 1000;

  // Helper function to find the start of a 15-minute interval
  const getIntervalStart = (timestamp) => {
    return timestamp - (timestamp % intervalMs);
  };

  // Group data by 15-minute intervals
  const groupedData = {};

  data.forEach((bar) => {
    const intervalStart = getIntervalStart(bar.time);

    if (!groupedData[intervalStart]) {
      groupedData[intervalStart] = {
        open: bar.open,
        high: bar.high,
        low: bar.low,
        close: bar.close,
        time: intervalStart
      };
    } else {
      const interval = groupedData[intervalStart];
      interval.high = Math.max(interval.high, bar.high);
      interval.low = Math.min(interval.low, bar.low);
      interval.close = bar.close;
    }
  });

  return Object.values(groupedData); // Return as an array
}

let nextTime = undefined;
export function _processHistoryResponseInRange(resolution, response, periodParams) {
  if (periodParams.firstDataRequest) {
    nextTime = undefined;
  }
  if (response.length === 0) {
    return { bars: [], meta: { noData: true } };
  }
  const bars = [];
  let from = periodRange(resolution, periodParams.from);
  let to = periodRange(resolution, periodParams.to);
  const shift = shouldShift(resolution);

  for (let i = response.length - 1; i >= 0; i -= 1) {
    // validate range
    if (from < periodParams.from) {
      from = periodParams.from;
    }
    if (to > periodParams.to) {
      to = periodParams.to;
    }
    let time = response[i][0];
    if (shift) {
      time = unshiftDST(response[i][0]);
    }
    if (!(time >= from && time <= to)) {
      nextTime = time;
      continue;
    }
    bars.unshift({
      open: response[i][1],
      high: response[i][2],
      low: response[i][3],
      close: response[i][4],
      time: time * 1000
    });
  }
  return { bars, meta: { noData: false, nextTime: bars.length === 0 ? nextTime : undefined } };
}

function getSymbolFromURL() {
  const active = getMenuFromLocalstorage();
  return active?.active;
}

function getInstruments() {
  const fromStorage = localStorage.getItem('symbols.trading.data');
  let data = JSON.parse(fromStorage) ?? [];
  return data;
}

let symbol = '';
let previousSymbol = null;
let listArray = [];
const cached = {};

const dataPulseProvider = {
  _subscribers: {},
  _requestsPending: 0,
  _quote: {},
  _latestBar: {},
  _emitter: emitter.instance,
  subscribeBars: function (symbolInfo, resolution, newDataCallback, listenerGuid) {
    if (this._subscribers[listenerGuid]) {
      logMessage(`DataPulseProvider: already has subscriber with id=${listenerGuid}`);
      return;
    }
    this._subscribers[listenerGuid] = {
      lastBarTime: null,
      listener: newDataCallback,
      resolution: resolution,
      symbolInfo: symbolInfo,
      call: null
    };
    this._latestBar[listenerGuid] = { ...cached[symbolInfo.name] };
    delete cached[symbolInfo.name];
    const self = this;
    setTimeout(async function () {
      const request = new TickRequest();
      const symbol = new SymbolName();
      symbol.setName(symbolInfo.name);
      request.addSymbols(symbol);

      const symbolClient = new SymbolClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      self._subscribers[listenerGuid].call = await symbolClient.onTick(request, metadata());
      const call = self._subscribers[listenerGuid].call;
      call.on('data', function (response) {
        const quote = {
          time: unshiftDST(response.u[0]),
          bid: response.u[1],
          ask: response.u[2]
        };
        const symbol = response.u[9];
        const tickData = {
          [symbol]: {
            bid: quote.bid,
            ask: quote.ask
          }
        };

        self._quote[listenerGuid] = quote;
        if (self._latestBar[listenerGuid]) {
          console.log(listenerGuid, self._latestBar[listenerGuid]);
          let bar = {};
          const time = periodRange(resolution, quote.time) * 1000;
          if (time > self._latestBar[listenerGuid].time) {
            bar = {
              open: quote.bid,
              high: quote.bid,
              low: quote.bid,
              close: quote.bid,
              time: time
            };
          } else {
            bar = {
              open: self._latestBar[listenerGuid].open,
              high: Math.max(self._latestBar[listenerGuid].high, quote.bid),
              low: Math.max(self._latestBar[listenerGuid].low, quote.bid),
              close: quote.bid,
              time: self._latestBar[listenerGuid].time
            };
          }
          self._latestBar[listenerGuid] = bar;
          setTimeout(() => {
            self._emitter.emit('Tick', tickData);
            self._emitter.emit('BidAsk', tickData);
          }, 0);
          newDataCallback(bar);
        }
      });
      call.on('status', (status) => {
        console.log('status', status);
      });
      call.on('error', (error) => {
        console.log('error', error);
      });
    }, 0);
    logMessage(
      `DataPulseProvider: subscribed for #${listenerGuid} - {${symbolInfo.name}, ${resolution}}`
    );
  },
  unsubscribeBars: function (listenerGuid) {
    this._subscribers[listenerGuid].call?.cancel();
    delete this._subscribers[listenerGuid];
    delete this._latestBar[listenerGuid];
    if (this._emitter) {
      const symbol = listenerGuid.split('_')[0];
      this._emitter.off(symbol, () => {});
    }
    logMessage(`DataPulseProvider: unsubscribed for #${listenerGuid}`);
  },

  _updateData: function () {
    if (this._requestsPending > 0) {
      return;
    }

    this._requestsPending = 0;
    for (const listenerGuid in this._subscribers) {
      this._requestsPending += 1;
      this._updateDataForSubscriber(listenerGuid)
        .then(() => {
          this._requestsPending -= 1;
          logMessage(
            `DataPulseProvider: data for #${listenerGuid} updated successfully, pending=${this._requestsPending}`
          );
        })
        .catch((reason) => {
          this._requestsPending -= 1;
          logMessage(
            `DataPulseProvider: data for #${listenerGuid} updated with error=${getErrorMessage(
              reason
            )}, pending=${this._requestsPending}`
          );
        });
    }
  }

  // _updateDataForSubscriber: async function (listenerGuid) {
  //   const subscriptionRecord = this._subscribers[listenerGuid];
  //   const range = window.moment().utc().unix();
  //   return ChartService.getInstance()
  //     .getBars({
  //       countback: 2,
  //       symbol: subscriptionRecord.symbolInfo.name,
  //       resolution: subscriptionRecord.resolution,
  //       from: range,
  //       to: range
  //     })
  //     .then((response) => {
  //       if (response) {
  //         const bars = [];
  //         if (response.o.length < 2) return;
  //         for (let i = 0; i < response.c.length; i += 1) {
  //           bars.push({
  //             open: response.o[i],
  //             high: response.h[i],
  //             low: response.l[i],
  //             close: response.c[i],
  //             time: response.t[i] * 1000
  //           });
  //         }
  //         if (this._quote[listenerGuid]) {
  //           bars[1].close = this._quote[listenerGuid].bid;
  //           bars[1].low =
  //             bars[1].low > this._quote[listenerGuid].bid
  //               ? this._quote[listenerGuid].bid
  //               : bars[1].low;
  //           bars[1].high =
  //             bars[1].high < this._quote[listenerGuid].bid
  //               ? this._quote[listenerGuid].bid
  //               : bars[1].high;
  //         }
  //         this._latestBar[listenerGuid] = bars[1];
  //         this._onSubscriberDataReceived(listenerGuid, {
  //           bars: bars
  //         });
  //       }
  //     });
  // },

  // _onSubscriberDataReceived: function (listenerGuid, result) {
  //   // means the subscription was cancelled while waiting for data
  //   if (!this._subscribers.hasOwnProperty(listenerGuid)) {
  //     logMessage(
  //       `DataPulseProvider: Data comes for already unsubscribed subscription #${listenerGuid}`
  //     );
  //     return;
  //   }

  //   const bars = result.bars;
  //   if (bars.length === 0) {
  //     return;
  //   }

  //   const lastBar = bars[bars.length - 1];
  //   const subscriptionRecord = this._subscribers[listenerGuid];

  //   if (subscriptionRecord.lastBarTime !== null && lastBar.time < subscriptionRecord.lastBarTime) {
  //     return;
  //   }

  //   const isNewBar =
  //     subscriptionRecord.lastBarTime !== null && lastBar.time > subscriptionRecord.lastBarTime;

  //   // Pulse updating may miss some trades data (ie, if pulse period = 10 secods and new bar is started 5 seconds later after the last update, the
  //   // old bar's last 5 seconds trades will be lost). Thus, at fist we should broadcast old bar updates when it's ready.
  //   if (isNewBar) {
  //     if (bars.length < 2) {
  //       throw new Error('Not enough bars in history for proper pulse update. Need at least 2.');
  //     }

  //     const previousBar = bars[bars.length - 2];
  //     subscriptionRecord.listener(previousBar);
  //   }

  //   subscriptionRecord.lastBarTime = lastBar.time;
  //   subscriptionRecord.listener(lastBar);
  // }
};

const datafeedV2 = {
  _dataPulseProvider: dataPulseProvider,
  onReady: function (callback) {
    symbol = getSymbolFromURL();
    previousSymbol = getSymbolFromURL();
    listArray = getInstruments();
    setTimeout(() => {
      callback(configurationData);
    }, 0);
  },
  searchSymbols: function (userInput, exchange, symbolType, onResult) {
    const symbols = [];
    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });
    setTimeout(() => {
      onResult(newSymbols);
    }, 0);
  },
  resolveSymbol: function (symbolName, onResolve, onError) {
    const item = listArray.find((e) => e.symbol === symbolName);
    if (item) {
      const priceScale = 10 ** item?.digit;
      const symbolInfo = {
        ticker: item.full_name,
        name: item.symbol,
        description: item.description,
        type: item.type,
        session: '24x7',
        timezone: TZ,
        exchange: item.exchange,
        minmov: 1,
        pricescale: priceScale,
        has_intraday: true,
        visible_plots_set: true,
        intraday_multipliers: ['1', '5', '15', '30', '60', '240', '1D'],
        supports_group_request: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2
      };
      setTimeout(() => {
        onResolve(symbolInfo);
      }, 0);
    } else {
      setTimeout(() => {
        onError('cannot resolve symbol');
      }, 0);
    }
  },
  getBars: async function (symbolInfo, resolution, periodParams, onResult) {
    setTimeout(() => {
      const { firstDataRequest } = periodParams;
      const historyClient = new HistoryClient(serverApiConfig(), null, null);
      const request = new OHLCRequest();
      request.setSymbol(symbolInfo.name);
      const from = shiftDST(periodParams.from);
      const to = shiftDST(periodParams.to);
      request.setFrom(moment.unix(from).add(-5, 'days').unix());
      request.setTo(to);
      const tf = getTimeframeInSecond(resolution);
      request.setTimeFrame(tf);
      historyClient.getOHLC(request, metadata(), async (err, response) => {
        if (response === null) {
          onResult([], { noData: true });
        } else {
          const result = _processHistoryResponseInRange(resolution, response.u[0], periodParams);
          if (periodParams.firstDataRequest) {
            cached[symbolInfo.name] = result.bars[result.bars.length - 1];
          }
          onResult(result.bars, result.meta);
        }
      });
    }, 0);
  },
  subscribeBars: function (symbolInfo, resolution, onTick, listenerGuid) {
    this._dataPulseProvider.subscribeBars(symbolInfo, resolution, onTick, listenerGuid);
  },
  unsubscribeBars: function (listenerGuid) {
    this._dataPulseProvider.unsubscribeBars(listenerGuid);
  }
};

function getErrorMessage(error) {
  if (error === undefined) {
    return '';
  } else if (typeof error === 'string') {
    return error;
  }
  return error.message;
}

export function getTimeframeInSecond(resolution) {
  if (!shouldShift(resolution)) {
    return 86400; // seconds in day resolution
  }
  return 60; // 60 seconds
}

export function unshiftDST(timestamp) {
  let offset = 0;
  if (shouldCheckDST) {
    if (moment.tz('Europe/London').isDST()) {
      offset = -3600; // backward 1 hour in day light savings time to utc
    }
  }
  return timestamp + offset;
}

export function shiftDST(timestamp) {
  let offset = 0;
  if (shouldCheckDST) {
    if (moment.tz('Europe/London').isDST()) {
      offset = 3600; // backward 1 hour in day light savings time to utc
    }
  }
  return timestamp + offset;
}

export function shouldShift(resolution) {
  return !(resolution.includes('D') || resolution.includes('W') || resolution.includes('M'));
}

export function periodRange(resolution, timestamp) {
  if (resolution.includes('D')) {
    return moment.unix(timestamp).tz(TZ).startOf('day').unix();
  }
  if (resolution.includes('W')) {
    return moment.unix(timestamp).tz(TZ).startOf('week').unix();
  }
  if (resolution.includes('M')) {
    return moment.unix(timestamp).tz(TZ).startOf('month').unix();
  }
  const min = parseInt(resolution, 10);
  if (min >= 60) {
    const h = Math.floor(min / 60);
    const dt = moment.unix(timestamp).tz(TZ).startOf('hour');
    return dt.hour(dt.hour() - (dt.hour() % h)).unix();
  }
  const dt = moment.unix(timestamp).tz(TZ).startOf('minute');
  return dt.minute(dt.minute() - (dt.minute() % min)).unix();
}

export default datafeedV2;
